.topsearch,
.sidesearch {
  max-width: 300px;
}

.servicesearch {
  max-width: 600px;
  margin-top: 15px;
}

.searchpage ul.searchresults {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.searchpage ul.searchresults li {
  margin-bottom: 20px;
  border-radius: 3px;
  padding-bottom: 20px;
}
.searchpage ul.searchresults li a {
  color: inherit;
  text-decoration: none;
  line-height: 170%;
  display: block;
  padding: 20px;
}
.searchpage ul.searchresults li a .title {
  padding-bottom: 5px;
  color: #807d79;
  font-weight: 500;
  font-size: 1.25rem;
}
.searchpage ul.searchresults li a .link {
  padding-top: 5px;
  color: #807d79;
}
.searchpage ul.searchresults li a .viewmore {
  float: right;
  color: #75bcd6;
}
.searchpage ul.searchresults li a:hover .link {
  text-decoration: underline;
}
.searchpage ul.searchresults li a > * {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}