.webshop-checkout__shippingmethod.webshop-checkout--isfree .webshop-checkout__cost {
  display: none;
}

.webshop-checkout__shippingmethod.webshop-checkout--isfree .webshop-checkout__costs:after {
  content: "gratis";
  color: #5cb85c;
  font-weight: 600;
  font-size: 11px;
}

html:not(.skw--offersin3) .skw-checkout__in3 {
  display: none;
}

.webshop-checkout__paymentmethod.webshop-checkout--isfree .webshop-checkout__costs {
  display: none;
}

.wh-form__label[for=orderform-wrd_lastname],
.wh-form__label[for="orderform-billing_address.nr_detail"],
.wh-form__label[for="orderform-billing_address.city"],
.wh-form__label[for="orderform-shipping_address.nr_detail"],
.wh-form__label[for="orderform-shipping_address.city"] {
  visibility: hidden;
  width: 0;
}

.wh-form__label[for="orderform-billing_address.zip"],
.wh-form__label[for="orderform-shipping_address.zip"] {
  white-space: nowrap;
}

html.wh-wrdauth--isloggedin .skw-checkout__loginline {
  display: none;
}

html:not(.skw--showcompanyfields) .skw-checkout__inlinecompanyfields {
  display: none;
}

html:not(.skw--showrepeatedcart) .skw-checkout__repeatedcart {
  display: none;
}

.wh-wrdauth__loginbutton {
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  outline: 0;
  cursor: pointer;
  background-color: #75b325;
  border-color: #75b325;
  color: #fff !important;
  border-radius: 50rem;
}
.wh-wrdauth__loginbutton:hover {
  color: #fff;
  text-decoration: none;
  background-color: #86cc2b;
  border-color: #86cc2b;
}
.wh-wrdauth__loginbutton:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

html:not(.skw--isspraypay) .skw-checkout__spraypay {
  display: none;
}

.orderstatusblock__text #popup-spraypay {
  top: 0;
}