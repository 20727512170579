.scroll-down header {
  transform: translate3d(0, -100%, 0);
}

.scroll-up header {
  transform: none;
  position: sticky;
  top: 0;
}

header {
  position: relative;
  z-index: 10;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  transition: transform 0.4s;
}
header:after {
  clear: both;
  display: block;
  content: "";
}
header .logo {
  display: inline-block;
  margin: 10px 0;
}
header .logo > img {
  height: 100%;
  width: 265px;
}
@media (min-width: 331px) and (max-width: 992px) {
  header .logo > img {
    width: 180px;
  }
}
@media (max-width: 330px) {
  header .logo > img {
    width: 160px;
  }
}
header .topmidpanel .topsearchform,
header .searchform-wrapper .topsearchform {
  white-space: nowrap;
  border-radius: 50rem;
}
header .topmidpanel .topsearchform input,
header .searchform-wrapper .topsearchform input {
  border-left: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
  border-right: 0;
  outline: none;
  -webkit-appearance: none;
}
header .topmidpanel .topsearchform button,
header .searchform-wrapper .topsearchform button {
  border-right: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
  color: #444;
}
header .toprightpanel a {
  color: inherit;
  text-decoration: none;
}
header .toprightpanel .list-inline-item:last-child {
  position: relative;
}
header .toprightpanel .badge {
  background: #75bcd6;
  position: absolute;
  top: -7px;
  right: 14px;
  font-size: 80%;
  border-radius: 100%;
  padding: 4px;
  min-width: 20px;
}
@media (max-width: 565px) {
  header .toprightpanel .badge {
    right: 4px;
  }
}
header .toprightpanel .label {
  display: block;
  font-size: 12px;
}
header .toprightpanel .service-icon,
header .toprightpanel .user-icon,
header .toprightpanel .cart-icon {
  display: block;
  width: 28px;
  height: 28px;
  margin: 0 auto;
}
header .toprightpanel .service-icon {
  background-image: url("../web/img/icon-service.svg");
}
header .toprightpanel .user-icon {
  background-image: url("../web/img/user.svg");
}
header .toprightpanel .cart-icon {
  background-image: url("../web/img/cart.svg");
}
@media (min-width: 320px) and (max-width: 390px) {
  header .toprightpanel .label {
    font-size: 9px;
  }
  header .toprightpanel .list-inline-item:not(:last-child) {
    margin-right: 0.3rem;
  }
}
@media (max-width: 992px) {
  header .navbar {
    padding: 0;
  }
  header .searchform-wrapper .topsearchform {
    margin-top: 0;
    width: 248px;
  }
  header .toprightpanel:before,
  header .toprightpanel .topnav {
    display: none;
  }
}
@media (max-width: 767px) {
  header .navbar {
    padding: 0;
  }
  header .myaccount {
    display: none;
  }
}
@media (min-width: 360px) and (max-width: 576px) {
  header .searchform-wrapper .topsearchform {
    width: 248px;
  }
  header .searchform-wrapper .topsearchform .input-group > .form-control {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 50rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  header .searchform-wrapper .topsearchform .input-group > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 50rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  header .searchform-wrapper .topsearchform .input-group > .form-control:not(textarea) {
    height: calc(1.5em + 0.5rem + 2px);
  }
}
@media (min-width: 320px) and (max-width: 359px) {
  header .searchform-wrapper .topsearchform {
    margin-top: 0;
    width: 195px;
  }
  header .searchform-wrapper .topsearchform .input-group > .form-control {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 50rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  header .searchform-wrapper .topsearchform .input-group > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 50rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  header .searchform-wrapper .topsearchform .input-group > .form-control:not(textarea) {
    height: calc(1.5em + 0.5rem + 2px);
  }
}

.navbar-light {
  clear: both;
}

.navbar {
  padding: 0 1rem;
}

.navbar-brand {
  font-size: 15px;
  margin-right: 0;
}
@media (max-width: 575px) {
  .navbar-brand {
    padding: 0;
  }
}

.navbar-toggler {
  border: none;
}

#navbar-wrapper {
  border-top: 1px solid #ccc;
}
@media (max-width: 575px) {
  #navbar-wrapper .container {
    padding: 0 5px;
  }
  #navbar-wrapper .container .navbar-toggler {
    padding: 0.4rem 0.5rem;
  }
}

#mainnav {
  width: 100%;
}
#mainnav .toplevelmenu__container .nav-link {
  color: #000;
}
#mainnav .toplevelmenu__container .nav-link i {
  color: #807d79;
}
#mainnav .toplevelmenu__container .toplevelmenu {
  margin: 0 auto;
  padding: 0;
  position: relative;
}
#mainnav .toplevelmenu__container .toplevelmenu__item.dropdown:hover > .dropdown-menu {
  display: block;
}
#mainnav .toplevelmenu__container li {
  display: inline-block;
}
#mainnav .toplevelmenu__container li.bg-primary {
  position: relative;
  top: -2px;
  height: 43px;
}
#mainnav .toplevelmenu__container li:hover li a:hover {
  color: #000;
  text-decoration: underline;
}
#mainnav .dropdown-menu {
  width: max-content;
  padding: 2rem;
  margin: 0;
  background-color: #fff;
  border-radius: 6px;
}
#mainnav .dropdown-menu p.normal {
  display: none;
}
#mainnav .dropdown-menu ul {
  display: block;
  float: left;
  padding-left: 0;
  margin-right: 20px;
  margin-bottom: 0;
}
#mainnav .dropdown-menu ul li {
  display: block;
  color: #000;
  position: relative;
  margin-left: 0;
  padding: 5px 10px;
}
#mainnav .dropdown-menu ul li a {
  text-decoration: none;
  white-space: nowrap;
  color: #000;
}
#mainnav .dropdown-menu ul li strong, #mainnav .dropdown-menu ul li b {
  font-size: 18px;
}

@media only screen and (min-width: 911px) and (max-width: 1200px) {
  header #mainnav .nav-link {
    padding: 0.5rem;
  }
}
@media (min-width: 767px) {
  .navbar-expand-lg {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}