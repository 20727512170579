.webshop--iscategorypage .usps__skw,
.webshop--istagpage .usps__skw {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0);
}
.webshop--iscategorypage .webshop-products main,
.webshop--istagpage .webshop-products main {
  position: relative;
  padding-bottom: 100px;
  /*
  the SVG has a 300 x 69 aspect ratio
  */
  --background-height: 441px; /* fallback */
  --background-wave-height: calc(var(--background-width) / 300 * 69);
  background: url(../../web/img/golven-categoryintro.svg) no-repeat center calc(var(--background-height) - var(--background-wave-height)), linear-gradient(#F7FBFE 0px, #F7FBFE calc(var(--background-height) - var(--background-wave-height)), #FFFFFF calc(var(--background-height) - var(--background-wave-height) + 1px), #FFFFFF 100%);
}

.main--with-categoryintro {
  background: none !important;
}

.category__intro {
  position: relative;
  padding-bottom: 30px;
  background: url(../../web/img/golven-categoryintro.svg) no-repeat bottom center #f7fbfe;
}
.category__intro #categoryintro_collapse.collapse:not(.show) {
  display: block;
}
@media (max-width: 992px) {
  .category__intro {
    margin-bottom: 10px;
  }
  .category__intro #categoryintro_collapse {
    margin-top: -65px;
  }
  .category__intro #categoryintro_collapse h1 {
    padding: 0.5rem 1rem;
    background-color: #f7fbfe;
    max-width: fit-content;
    border-radius: 0.4rem;
  }
  .category__intro #categoryintro_collapse.collapse:not(.show) {
    display: block;
    height: 120px;
    overflow: hidden;
  }
  .category__intro #categoryintro_collapse.collapsing {
    height: 3rem;
  }
  .category__intro button.collapsed:after {
    content: "+ Lees meer";
  }
  .category__intro button:not(.collapsed):after {
    content: "- Lees minder";
  }
}
.category__intro__img {
  width: 100%;
  position: absolute;
  bottom: -1px;
}

.page--closetcategorypage p {
  line-height: 28px;
}

@media (max-width: 767px) {
  nav .breadcrumb {
    display: none;
  }
}
@media (max-width: 565px) {
  .product_listing .g-0 > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
  }
}
.webshop-products--ordering {
  border-radius: 50rem;
}

.product_listing {
  position: relative;
}

.productsgrid--categories + h2 {
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  margin: 20px 0;
}

@media (max-width: 992px) {
  .btn-showfilter {
    display: inline-block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 0.4rem 1rem;
  }
}
/* anchor/link for mobile filtering */
#productsgrid_anchor {
  position: absolute;
  top: -150px;
  left: 0;
}

/* anchor/link for mobile layout */
.productsgrid__bottomanchorlink {
  display: inline-block;
  margin-bottom: 10px;
}

.productsgrid {
  clear: both;
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 0;
}
@media (max-width: 992px) {
  .productsgrid {
    overflow: hidden;
  }
}
.productsgrid__product {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  display: block;
  margin-bottom: 1.5rem;
  padding: 0 15px;
}
.productsgrid__product--hidden {
  display: none;
}
@media (max-width: 1199px) {
  .productsgrid__product {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .productsgrid__product {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 10px;
  }
  .productsgrid__product:nth-child(odd) {
    padding-right: 5px;
  }
  .productsgrid__product:nth-child(even) {
    padding-left: 5px;
  }
}
@media (767px) and (min-width: 420px) {
  .productsgrid__product {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 10px;
    padding: 0 10px;
  }
}
@media (max-width: 419px) {
  .productsgrid__product {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .productsgrid__product:nth-child(odd) {
    padding-right: 15px;
  }
  .productsgrid__product:nth-child(even) {
    padding-left: 15px;
  }
}

@media (min-width: 480px) {
  .combinesection__products .productsgrid {
    margin: 0 -10px;
  }
  .combinesection__products .productsgrid > li {
    flex: 0 0 20%;
    max-width: 20%;
    padding: 0 10px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .combinesection__products .productsgrid > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .combinesection__products .productsgrid > li {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}
@media (max-width: 767px) and (min-width: 480px) {
  .combinesection__products .productsgrid > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.combinesection__products .productsgrid .productitem__baseprice > .price {
  font-size: 16px;
}
.combinesection__products .productsgrid .productitem__nowprice > span {
  font-size: 21px;
}

@media (min-width: 1200px) {
  .productsgrid.products-slider .slick-list {
    overflow: unset;
  }
}
.productsgrid.products-slider .productsgrid__product .productitem__baseprice > .price {
  font-size: 16px;
}
.productsgrid.products-slider .productsgrid__product .productitem__baseprice > span {
  font-size: 21px;
}
@media (max-width: 1199px) {
  .productsgrid.products-slider .productsgrid__product {
    flex: unset;
    max-width: none;
  }
}
@media (max-width: 767px) {
  .productsgrid.products-slider .productsgrid__product {
    flex: unset;
    max-width: none;
  }
}
@media (max-width: 479px) {
  .productsgrid.products-slider .productsgrid__product {
    flex: unset;
    max-width: none;
  }
}
.productsgrid .slick-track {
  display: flex !important;
}
.productsgrid .slick-slide {
  height: auto;
}

.productsgrid--categories {
  margin: 0px -15px 15px -15px;
  padding: 15px 0 0 0;
}

@media (max-width: 767px) {
  .productsgrid--categories {
    margin: 0 -15px 20px;
    padding: 34px 0;
  }
  .productsgrid--categories .productsgrid__product {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .productsgrid--categories .productsgrid__product:nth-child(odd) {
    padding-right: 15px;
  }
  .productsgrid--categories .productsgrid__product:nth-child(even) {
    padding-left: 15px;
  }
}