#sidebar .widget
{
  background-color: #fff;
  max-width: 400px;
}
.widget
{
  margin-bottom: 1rem;
}
  .widget--mobile
, .widget--desktop
{
  display: none;
}
@media(max-width: 767px)
{
  .widget--mobile
  {
    display: block;
  }
}
@media(min-width: 768px)
{
  .widget--desktop
  {
    display: block;
  }
}
.widget-subcategorylist
{
  padding: 15px;
  background: rgba(255, 255, 255, 0.6);
}
.widget-categorybuttons b
{
  color:#5a5a58;
}
/* productfilter(s) */
.widget-productfilter--hidden
{
  display: none;
}
.btn-showfilter
{
  cursor: pointer;
}
.productsgrid[data-filterconfig="null"] .widget-mobileproductfilter
{
  display:none;
}
.widget-productfilter
{
  padding: 15px;
  background: rgba(255, 255, 255, 0.6);
}
#filterSideNav .widget-productfilter
{
  padding: 0;
}
#filterSideNav .filtertype--checkbox::-webkit-scrollbar
{
    -webkit-appearance: none;
    width: 7px;
}
#filterSideNav .filtertype--checkbox::-webkit-scrollbar-thumb
{
    background-color: #40403e;
    border-radius: 0;
    border: 0 solid #fff;
}
#filterSideNav .filtertype--checkbox::-webkit-scrollbar-track
{
    border-radius: 0;
    background-color: #807d79;
}
.productfilter__activetitle
{
  margin-bottom: 5px;
}
.productfilter__activelabel
{
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 25px 5px 10px;
  font-style: italic;
  font-size: 13px;
  border-radius: 50rem;
  margin-bottom: 3px;
  margin-right: 5px;
  color:#a89895;
  display: inline-block;
}
.productfilter__activeremove:before
{
  content: "x";
  position:  relative;
  right: -8px;
  color:red;
  font-size: 16px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
}

.productfilter .filtertype--checkbox,
.productfilter .filtertype--select
{
    max-height: 215px;
    overflow-x: hidden;
    overflow-y: auto;
}

.productfilter:last-child
{
  padding-bottom: 0;
}
.productfilter-title
{
  padding: 5px 0;
  margin: 10px 0;
  display: block;
  font-size: 18px;
  color: #5a5a58;
  font-family: "Rosario", sans-serif;
  font-weight: 800;
}
.productfilter-title[data-bs-toggle="collapse"]:after
{
  content: "\f106"; /* fa-angle-up  */
  font-family: 'Font Awesome 5 Pro';
  float: right;
  font-size: 18px;
}

.productfilter-title[data-bs-toggle="collapse"].collapsed:after
{
  content: "\f107"; /* fa-angle-down */
  font-family: 'Font Awesome 5 Pro';
  float: right;
  font-size: 18px;
}

.productfilter--optionlist
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.productfilter--optionlist li
{
  display: flex;
  align-items: start;
}
.productfilter--optionlist li input[type="checkbox"]
{
  position: relative;
  top: 6px;
}
.productfilter--optionlist li label
{
  padding-left: 5px;
}
.productfilter--select
{
  width: 100%;
  padding: 5px;
}


#filterSideNav .widget-productfilter .aside__title
{
  color:#fff;
  font-weight: 400;
}
#filterSideNav .productfilter__activelabel
{
  background-color: #756356;
  color:#fff;
  border:none;
}
#filterSideNav .productfilter + .productfilter
{
  border-top:0;
}

#filterSideNav .widget-productfilter
{
  border-bottom:0;
  background-color: transparent;
}
#filterSidenav .wh-slider-holder>.wh-slider
{
  border:none;
}
#filterSideNav .productfilter--optionlist li label,
#filterSideNav .productfilter__activetitle,
#filterSideNav .wh-slider-holder > .wh-slider > .wh-slider-knob
{
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}
#filterSideNav ul li
{
  border-bottom:0!important;
}
#filterSideNav .productfilter--select
{
  border:none;
}

/* The "x" icon on the tag for each selected filter */
#filterSideNav .productfilter__activeremove:before
{
  color:#fff;
}

#filterSideNav  .productfilter--optionlist li label
{
    margin-bottom: 5px;
}
#filterSideNav  .productfilter--optionlist li input[type="checkbox"]
{
  position: relative;
  top: 5px;
}
@media(max-width: 767px)
{
  #sidebar .widget-productfilter,
  .widget-categorylist
  {
    display: none;
  }
}

/**/
.sidereview .rating-stars-count
{
  display: block;
}
.sidereview .ratingcomment
{
  clear: both;
  margin-top: 5px;
  background-color: #eee;
  position: relative;
}
.sidereview .ratingcomment:after
{
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  top: 100%;
  border-top: 10px solid #eee;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}
.sidereview .ratingcomment > *
{
  padding: 15px 10px;
}
.widget-inlinemultiplereviews .widget-inlinereview__comment
{
  color: #5a5a58;
  font-family: "Rosario", sans-serif;
  font-size: 19px;
  line-height: 1.4;
  font-style: italic;
}
.widget-inlinemultiplereviews .review__description
{
  background: url(../web/img/review-bg.jpg) no-repeat right top;
  margin-left: -50px;
  margin-top: 50px;
  padding-right: 25%!important;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px)
{
  .widget-inlinemultiplereviews .review__description
  {
    margin-left: 0;
    margin-top: 0;
    position: relative;
    z-index: 1;
  }
  .widget-inlinemultiplereviews .review__description p
  {
    display: none;
  }
}

.widget-inlinereview .widget-inlinereview__comment
{
  background-color: #f7fbfe;
  padding: 20px;
  position: relative;
  margin-bottom: 15px;
}
.widget-inlinereview .widget-inlinereview__comment:after
{
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 30px;
  border-left: 10px solid transparent;
  border-top: 10px solid #f7fbfe;
  border-right: 10px solid transparent;
}
.widget-inlinereview .widget-inlinereview__comment > *
{
  display: inline-block;
  margin: 0;
}
.widget-inlinereview .widget-inlinereview__comment > *:before
, .widget-inlinereview .widget-inlinereview__comment > *:after
{
  display: inline-block;
  content: '"';
}
.widget-inlinereview .widget-inlinereview__meta b
{
  padding-right: 10px;
}
.widget-inlinereview .widget-inlinereview__more
{
  margin-top: 10px;
  font-weight: 600;
}

/**/
.widget-fullwidthimage > img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
  position: relative;
  z-index: 1;
}

.widget-fullwidthimage--withbottombar
{
  margin-bottom: clamp(10px, 180px, 18dvw);
}

.widget-fullwidthimage--withbottombar::after
{
  content: "";
  display: block;
  position: absolute;
  background-color: #ebeded;
  z-index: 0;
  height: clamp(80px, 315px, 31dvw);
  left: 0;
  right: 0;
  margin-top: clamp(-180px, -40px, -18dvw);
}


.widget-categorybanner
{
  width:100%;
}
.categorybannerimage
{
  padding:30px 20px 20px 20px;
}
.widget-homebanner
{
  clear: both;
  position: relative;
}
.widget-homebanner img.golf
{
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translate(-50%,0);
}
.widget-homebanner .homebannerimage
{
  position: relative;
}

@media (min-width: 767px)
{
  .widget-homeintro h1
  {
    font-size: 3rem;
    font-weight: 600;
    line-height: 1;
  }
  .widget-homeintro h1 span
  {
    font-size: 2.5rem;
  }
  .widget-homeintro p
  {
    font-size: 1.1rem;
    line-height: 1.5;
  }
}
.usps__skw
{
    padding: 10px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid #dee1e1;
    box-shadow: 0 0 7px rgba(0,0,0,.2);
}
.usps__skw .icon_wrapper
{
    margin-right: 10px;
    display: inline-block;
    width: 36px;
    height: 30px;
}
.usps__skw a {
    color: #5a5a58;
    display: flex;
    align-items: center;
}
.widget-categoryblocks
{
  padding: 15px 0;
}
.widget-categoryblocks .row > *
{
  margin-top: 0!important;
}
.widget-categoryblocks .card-body
{
  position: relative;
}
.widget-categoryblocks .categoryblock__title
{
    color: #5a5a58;
    font-size: 1.3rem;
    background-color: #fff;
    display: inline-block;
    position: absolute;
    top: -33px;
    left: 0;
    padding: .6rem 1rem 0 1rem;
    border-top-right-radius: .4rem;
}
.widget-categoryblocks .float-end
{
  position: relative;
  top: 10px;
}
.widget-categoryblocks p
{
  color: #807d79;
}
.widget-closet
{
  background: url(../web/img/closet-banner-bg.jpg) no-repeat center bottom;
  padding:50px 0;
}
.widget-bed
{
  background: linear-gradient(to bottom,  #ffffff 0%,#f1f1f1 100%);
  position: relative;
}
.widget-bed .container
{
  position: relative;
  z-index: 1;
}
.widget-bed:after
{
  content: "";
  width: 100%;
  height: 70px;
  background-color: #fff;
  position: absolute;
  bottom: -7px;
  left: 0;
}
.widget-bed .col-lg-6:nth-child(3)
{
  margin-top: 75px;
  margin-left: -75px;
}
@media (max-width: 767px)
{
  .widget-bed .col-lg-6:nth-child(3)
  {
    margin-top: -30px;
    margin-left: auto;
  }
}

/**/
.widget-button,
.widget-chatbutton
{
  display: inline-block;
  margin-right: 20px;
}
.widget-button .btn,
.widget-chatbutton .btn
{
  text-align: left;
  min-width: 185px;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.widget-chatbutton i,
.widget-button i
{
  font-size: 24px;
  display: block;
  float: left;
  margin-bottom: 5px;
  padding-top: 5px;
  margin-right: 10px;
}

.widget-button i
{
  display: block;
  float: left;
  margin-right: 10px;
}

.widget-chatbanner
{
  margin: 20px 0 20px;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 0 7px rgba(0,0,0,.2);
  background: url(../web/img/hulp-bg.jpg) no-repeat 100% 0 #fff;
  background-size: auto 100%;
  padding-right: 160px;
}
.widget-reviewbanner
{
  margin:100px 0 50px;
}
.widget-reviewbanner .bg-white
{
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
  position: relative;
  z-index: 1;
}
.widget-reviewbanner h2
{
  font-size: 32px;
}
.widget-reviewbanner img
{
  margin-left: -50px;
  margin-top: -75px;
}
.widget-reviewbanner .fa-star
{
    color:#ffa200;
}
@media( max-width: 992px)
{
  .widget-reviewbanner img
  {
    margin-left: -15px;
    margin-top: -80px;
    margin-bottom: -50px;
  }
  .widget-chatbanner
  {
    padding-right: 20px;
  }
}

@media( max-width: 767px)
{
  .widget-reviewbanner img
  {
    margin-left: -15px;
    margin-top: -80px;
    margin-bottom: -50px;
  }
  .widget-chatbanner
  {
    padding-right: 160px;
  }
}

@media( max-width: 565px)
{
  .widget-reviewbanner img
  {
    margin-left: -15px;
    margin-top: -80px;
    margin-bottom: -50px;
  }
  .widget-chatbanner
  {
    padding-right: 20px;
  }
}

/* widget faq */
.widget-faq .card
{
  margin-bottom: 10px;
  border:1px solid #ddd!important;
  box-shadow: 0 2px rgba(0,0,0,.1);
}
.widget-faq .card-header
{
  background-color: #f7fbfe;
}

.widget-faq .card .card-header
{
  padding:0 5px;
  margin-bottom: 0;
}
.widget-faq .btn-link
{
  color:#5a5a58;
  display: block;
  width: 100%;
  text-align: left;
  padding: .5rem;
  font-weight: 600;
}
.widget-faq .card-header .btn:after
{
  font-family: 'Font Awesome 5 Pro';
  content: "\f068"; /* fa-minus */
  float: right;
  font-size: 14px;
  color: #74c8ef;
}
.widget-faq .card-header .btn.collapsed:after
{
  content: "\f067"; /* fa-plus */
}

/* widget collapse */
.widget-collapse .card
{
  margin-bottom: 10px;
  border-radius: 6px;
  border:1px solid #75bcd6!important;
}
.widget-collapse .card-header
{
  background-color: #f7fbfe;
  border-radius: 6px;
  padding: 5px 10px;

}
.widget-collapse .card-header h4 button:hover
{
  text-decoration: none;
}

.widget-collapse .card .card-header
{
  padding:0 5px;
  margin-bottom: 0;

}
.widget-collapse .btn-link
{
  color:#5a5a58;
  display: block;
  width: 100%;
  text-align: left;
  padding: .5rem;
  font-weight: 600;
  text-decoration: none;
  font-family:  'Nunito', sans-serif;
}
.widget-collapse .btn-link:focus
{
  box-shadow: none;
}
.widget-collapse .card-header .btn[aria-expanded="false"]:after
{
  font-family: 'Font Awesome 5 Pro';
  content: "\f067"; /* fa-plus */
  font-size: 16px;
  color: #75bcd6;
  position: absolute;
  right: 15px;
}
.widget-collapse .card-header .btn[aria-expanded="true"]:after
{
  font-family: 'Font Awesome 5 Pro';
  content: "\f068"; /* fa-minus */
  font-size: 16px;
  color: #75bcd6;
  position: absolute;
  right: 15px;
}


.widget-newsletter
{
  max-width: 450px;
  background-color: #f7fbfe;
}
.widget-newsletter__description
{
  font-size: 21px;
  line-height: 1.2;
}

@media (max-width: 767px)
{
  #product__tabs .tabs_container .widget-collapse .card,
  #product__tabs .tabs_container .widget-faq .card
  {
    border-radius: .25rem;
  }
  #product__tabs .tabs_container .widget-collapse .card .card-header,
  #product__tabs .tabs_container .widget-faq .card .card-header
  {
    background-color: #f7fbfe;
  }
}
/**/
.widget-categorieslist
{
  list-style-type: none;
  margin: 0;
  padding: 15px 5px 5px 14px;
  overflow: hidden;
}
.widget-categorieslist li
{
  float: left;
  width: 47%;
  margin-right: 3%;
  margin-bottom: 3%;
}

.widget-categorieslist a
{
  display: block;
  color: #5a5a58;
  position: relative;
  padding: 10px 15px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}
.widget-categorieslist a:after
{
  font-family: 'Font Awesome 5 Pro';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -10px;
  content: "\f105"; /* fa-angle-right */
  font-size: 16px;
}
.widget-categorieslist a:hover
{
  text-decoration: none;
}


/* contact*/
.widget-contact h4
{
  font-size: 18px;
  font-weight: 600;
}
.widget-contact ul
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-contact li
{
  border-bottom: 1px solid #ccc;
  padding: 8px 0;
}
  .widget-contact li > a
, .widget-contact li > .trengo-btn
{
  display: block;
  position: relative;
  padding-left: 27px;
  color: #337ab7;
  cursor: pointer;
}
  .widget-contact li > a:hover
, .widget-contact li > .trengo-btn:hover
{
  text-decoration: underline;
}
.widget-contact .fa, .widget-contact .far, .widget-contact .fas, .widget-contact .fab
{
  position: absolute;
  left: 0;
  right: 0;
  font-size: 19px;
  color: #4dadd8;
}

/* banner */
.widget-image__link
{
  color: #5a5a58;
}
.widget-image__link:hover
{
  color: inherit;
}
.widget-image img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

/* group for quicklink widgets */
.widget-widgetgroup
{
  display: flex;
  flex-flow: wrap;
  list-style-type: none;
  margin: 0 0 0 -30px;
  padding: 0;
}
.widget-widgetgroup > li,
.widget-widgetgroup.columns-2 > li
{
  flex: 1 1 50%;
  padding: 0 0 20px 30px;
}

.widget-widgetgroup.columns-3 > li
{
  flex: 1 1 33%;
  padding: 0 0 20px 30px;
}
.widget-widgetgroup.columns-4 > li
{
  flex: 1 1 25%;
  padding: 0 0 20px 30px;
}
.widget__innerlinks i
{
  float: right;
}
.page--fullwidth .widget-widgetgroup > li
{
  flex: 1 1 25%;
}
.widget-widgetgroup > li > .widget
{
  height: 100%;
  margin-bottom: 0;
}
@media( max-width: 600px )
{
  .widget-widgetgroup
  {
    display: block;
    margin-left: 0;
  }
  .widget-widgetgroup > li
  {
    flex: none;
    width: 100%;
    padding-left: 0!important;
  }
}
@media (min-width: 1200px)
{
  .widget__innerlinks .collapse:not(.show)
  {
    display: block;
  }
  .widget__innerlinks .card-header .btn
  {
    pointer-events: none;
  }
  .widget__innerlinks .card-header .btn[aria-expanded="false"]:after
  {
    content: "";
  }
  .widget__innerlinks .card-header .btn[aria-expanded="true"]:after
  {
    content: "";
  }
}
#sidebar .widget.border
{
  border:1px solid #ccc;
}
#sidebar .widget-customerservice h4
{
  font-weight: 800;
}
#sidebar .widget-customerservice
{
  padding: 15px;
}
#sidebar .customerservice__buttons
{
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
  flex-wrap: wrap;
}
#sidebar .customerservice__buttons li
{
  list-style: none;
  width: 100%;
  display: flex;
}
#sidebar .customerservice__buttons a,
#sidebar .customerservice__buttons .trengo-btn
{
  display: block;
  align-items: center;
  width: 100%;
  padding: .7em 1em;
  font-weight: 500;
  text-decoration: none;
  color: #5a5a58;
  background-color: #f7fbfe;
  margin-bottom: 10px;
  border:1px solid #75bcd6;
  border-radius: 50rem;
  line-height: 21px;
  transition: 0.2s ease;
  font-size: 13px;
}
#sidebar .customerservice__buttons a:hover,
#sidebar .customerservice__buttons .trengo-btn:hover
{
  background-color: #fff;
  cursor: pointer;
}
#sidebar .customerservice__buttons i
{
  font-size: 24px;
  display: block;
  float: left;
  margin-bottom: 10px;
  padding-top: 10px;
  margin-right: 5px;
  color:#74c8ef;
}
@media (min-width: 992px) and (max-width: 1199px)
{
  #sidebar .customerservice__buttons i
  {
    display: none;
  }
}
#sidebar .customerservice__buttons strong
{
  font-weight: 600;
  font-size: 16px;
}
.widget-quicklinks__title
{
  font-size: 18px;
  color: #5a5a58!important;
  padding: 20px 20px 0 20px;
  position: relative;
  display: flex;
  align-items: center;
}
.widget-quicklinks .skw-icon
{
  position: relative;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
}
.widget-quicklinks .skw-truck
{
  background-image: url('../web/img/icon-truck.svg');
}
.widget-quicklinks .skw-briefcase
{
  background-image: url('../web/img/icon-return.svg');
}
.widget-quicklinks .skw-money
{
  background-image: url('../web/img/icon-wallet.svg');
}
.widget-quicklinks .skw-clipboard
{
  background-image: url('../web/img/icon-warranty.svg');
}
.widget-quicklinks a
{
  color: #74c8ef;
}
.widget-quicklinks a:hover
{
  color: #74c8ef;
  text-decoration: underline;
}
.widget-quicklinks ul
{
  list-style-type: none;
  margin: 0;
  padding: 10px 20px;
}
.widget-quicklinks ul > li
{
  display: block;
  position: relative;
  padding: 2px 0 2px 15px;
}
.widget-quicklinks ul > li:before
{
  font-family: 'Font Awesome 5 Pro';
  display: inline-block;
  color: #4dadd8;
  position: absolute;
  top: 0;
  left: 0;
  content: "\f105"; /* fa-angle-right */
  font-size: 16px;
}

.widget-map
{
  padding-top: 75%;
  position: relative;
  width: 100%;
  background-color: #f1f1f1;
  overflow: hidden;
  cursor: pointer;
}
.widget-map:after
{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-map__gmap
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.widget-map__gmap .wh-infowindow
{
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  width: 40px;
  height: 40px;
  margin-top: -40px;
  margin-left: -20px;
}
.widget-map__gmap .wh-infowindow > *
{
  display: block;
}
.widget-map__gmap .wh-infowindow .title
{
  padding-top: 5px;
  font-size: 16px;
  line-height: 16px;

  width: 300px;
  margin-left: -130px;
}
.widget-textcol.grey-gradient,
.widget-twocol.grey-gradient
{
  background: linear-gradient(to bottom,  rgba(249,249,249,1) 0%,rgba(255,255,255,0) 100%);
}
.widget-textcol.waves-brown,
.widget-twocol.waves-brown
{
  background: url(../web/img/golven-bruin.svg) no-repeat 0 70%;
  background-size: cover;
}
.widget-textcol.waves-blue
.widget-twocol.waves-blue,
{
  background: url(../web/img/golven-blauw.svg) no-repeat 0 70%;
  background-size: cover;
}
.subcategorylist__list
{
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.subcategorylist__listitem
{
  list-style-type: none;
  padding: 4px 0;
  font-size: 16px;
}
.subcategorylist__listitem.active
{
  font-weight: 700;
}

.subcategorylist__listitemlink
{
  color: #5a5a58;
}

aside.widget-login
{
    background: #fff;
    margin: 0 0 30px 0;
    padding: 20px;
    overflow: hidden;
    background-color: #f7fbfe;
    border: 1px solid #75bcd6;
    border-radius: 6px;
}
.shadow__divider
{
  padding: 20px 0 20px 0;
  margin-bottom: 30px;
  margin-top: -30px;
  box-shadow: 0 1em 1em -1em rgba(0, 0, 0, .25);
}
html.wh-wrdauth--isloggedin .widget-login
{
  display:none;
}
.webshop--ishomepage .widget-mobileproductfilter
{
  display: none;
}
