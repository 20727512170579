.spc-pagination
{
  display: flex;
  width: 100%;

  column-gap: var(--pagination-prevnext-spacing);

  font: var(--pagination-font);

  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #DDDDDD; /* #5a5a58; */
}

/* When there's only a single page (or no results) the hidden attribute is used on .spc-pagination */
.spc-pagination[hidden]
{
  display: none;
}

.spc-pagination button
{
  border: 0;
  padding: 0;
  background: none;
  font: inherit;
  cursor: pointer;

  user-select: none;
}

.spc-pagination button[disabled]
{
  cursor: default;
}

  .spc-pagination__previous.spc-pagination__previous
, .spc-pagination__next.spc-pagination__next
{
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;

  border: var(--pagination-prevnext-border);
  color: var(--pagination-prevnext-icon-color);
  text-decoration: none;
  cursor: pointer;

  width:  var(--pagination-page-size);
  height: var(--pagination-page-size);
  background: var(--pagination-prevnext-background-color);
  border-radius: 50%;

  font: var(--fontawesome-solid);
  font-size: 16px;
}

.spc-pagination__previous::before
{
  content: var(--fa-arrow-left);
}

.spc-pagination__next::before
{
  content: var(--fa-arrow-right);
}

  .spc-pagination__previous:hover
, .spc-pagination__next:hover
{
  background: var(--pagination-prevnext-hover-background);
  border:     var(--pagination-prevnext-hover-border);
  color:      var(--pagination-prevnext-hover-icon-color);
}

  .spc-pagination__previous[disabled]
, .spc-pagination__next[disabled]
{
  background: var(--pagination-prevnext-disabled-background);
  border: var(--pagination-prevnext-disabled-border);
  color: #666666;
  cursor: default;
}


/* To prevent page layout blowup (and then using that new width for more buttons instead of shrinking):
   - .acoifilterpage__content.acoifilterpage__content { overflow: hidden; } -> don't stretch grid
   - .spc-pagination__pages { contain: size; } -> don't ever ask parent container to grow
*/

.spc-pagination__pages
{
  flex: 1 1 0px;
  contain:  size; /* child content isn't allowed to stretch this container (iOS 16+) */

  display: flex;
  justify-content: center;
  column-gap: var(--pagination-page-spacing);
}

.spc-pagination__ellipsis::before
{
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;

  width:  var(--pagination-page-size);
  height: var(--pagination-page-size);

  content: "…";
  padding-bottom: 14px;

  /*
  font: var(--fontawesome-light);
  content: var(--fa-ellipsis-h);
  */
  font-size: 26px;
}

.spc-pagination__page
{
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;

  width:  var(--pagination-page-size);
  height: var(--pagination-page-size);
  border-radius: var(--pagination-page-radius);

  color: var(--pagination-page-link-color);
  border: 2px solid transparent; /* ACOI-Paars */
}

button.spc-pagination__page:hover
{
  border: 2px solid #540A51; /* ACOI-Paars */
  background: var(--pagination-page-hover-background);
}

.spc-pagination__page.current
{
  border: 2px solid #540A51; /* ACOI-Paars */
}
