@charset "UTF-8";
.webshop--isproductpage {
  --productoptions-padding: 20px;
}
.webshop--isproductpage .webshop-product__ratinginfo {
  margin-bottom: 10px;
}

.product__options__group {
  padding: var(--productoptions-padding);
}

.productpage__reviewpagenavigation {
  margin-top: 30px;
  min-height: var(--pagination-page-size);
}

.productpage__writereviewbutton {
  margin-top: 40px;
}

@media (min-width: 320px) and (max-width: 375px) {
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages {
    height: 190px;
  }
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages img {
    min-width: 360px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages {
    min-height: 304px;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages {
    min-height: 405px;
  }
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages img {
    min-width: 550px;
  }
}
@media (min-width: 1200px) {
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages {
    min-height: 500px;
  }
  html.dshops--3dimages .threedimagesactive .product__images__preview_3dimages img {
    min-width: 875px;
  }
}
html.dshops--3dimages .threedimagesactive .product__3dimages__grid {
  display: none !important;
}
html.dshops--3dimages .product__3dimages__grid .slick-slide {
  opacity: 1;
}
html.dshops--3dimages .webshop-product__shortdescription {
  margin-bottom: 10px;
}
html.dshops--3dimages .dd-selected .dd-selected-image {
  display: none;
}
@media (max-width: 480px) {
  html.dshops--3dimages .dd-selected {
    padding: 4px 5px;
  }
}
html.dshops--3dimages .product__options .product__options__container {
  min-height: 360px;
}
@media (max-width: 767px) {
  html.dshops--3dimages .product__options .product__options__container {
    min-height: 285px;
  }
}
@media (max-width: 480px) {
  html.dshops--3dimages .product__options .product__options__container .webshop-product__options {
    margin-bottom: 2px;
  }
}
@media (max-width: 375px) {
  html.dshops--3dimages .product__options .product__options__container .webshop-product__options .col-3 label {
    word-wrap: unset;
  }
}
html.dshops--3dimages .product__options .option__kast-kleur,
html.dshops--3dimages .product__options .option__kleur-deuren,
html.dshops--3dimages .product__options .option__deur-kleur,
html.dshops--3dimages .product__options .option__linkerdeur,
html.dshops--3dimages .product__options .option__rechterdeur,
html.dshops--3dimages .product__options .option__dwarsbanen-deuren,
html.dshops--3dimages .product__options .option__kleur-linkerdeur,
html.dshops--3dimages .product__options .option__kleur-rechterdeur,
html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven,
html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen,
html.dshops--3dimages .product__options .option__kleur,
html.dshops--3dimages .product__options .option__kastromp,
html.dshops--3dimages .product__options .option__deuren,
html.dshops--3dimages .product__options .option__glas-op-deuren {
  margin-bottom: 5px;
}
@media (min-width: 320px) and (max-width: 379px) {
  html.dshops--3dimages .product__options .option__kast-kleur,
  html.dshops--3dimages .product__options .option__kleur-deuren,
  html.dshops--3dimages .product__options .option__deur-kleur,
  html.dshops--3dimages .product__options .option__linkerdeur,
  html.dshops--3dimages .product__options .option__rechterdeur,
  html.dshops--3dimages .product__options .option__dwarsbanen-deuren,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur,
  html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven,
  html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen,
  html.dshops--3dimages .product__options .option__kleur,
  html.dshops--3dimages .product__options .option__kastromp,
  html.dshops--3dimages .product__options .option__deuren,
  html.dshops--3dimages .product__options .option__glas-op-deuren {
    margin-bottom: 0;
  }
  html.dshops--3dimages .product__options .option__kast-kleur .col-9,
  html.dshops--3dimages .product__options .option__kleur-deuren .col-9,
  html.dshops--3dimages .product__options .option__deur-kleur .col-9,
  html.dshops--3dimages .product__options .option__linkerdeur .col-9,
  html.dshops--3dimages .product__options .option__rechterdeur .col-9,
  html.dshops--3dimages .product__options .option__dwarsbanen-deuren .col-9,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur .col-9,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur .col-9,
  html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .col-9,
  html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .col-9,
  html.dshops--3dimages .product__options .option__kleur .col-9,
  html.dshops--3dimages .product__options .option__kastromp .col-9,
  html.dshops--3dimages .product__options .option__deuren .col-9,
  html.dshops--3dimages .product__options .option__glas-op-deuren .col-9 {
    height: 30px;
  }
}
@media (max-width: 480px) {
  html.dshops--3dimages .product__options .option__kast-kleur,
  html.dshops--3dimages .product__options .option__kleur-deuren,
  html.dshops--3dimages .product__options .option__deur-kleur,
  html.dshops--3dimages .product__options .option__linkerdeur,
  html.dshops--3dimages .product__options .option__rechterdeur,
  html.dshops--3dimages .product__options .option__dwarsbanen-deuren,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur,
  html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven,
  html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen,
  html.dshops--3dimages .product__options .option__kleur,
  html.dshops--3dimages .product__options .option__kastromp,
  html.dshops--3dimages .product__options .option__deuren,
  html.dshops--3dimages .product__options .option__glas-op-deuren {
    margin-bottom: 10px;
  }
}
@media (max-width: 992px) {
  html.dshops--3dimages .product__options .option__kast-kleur,
  html.dshops--3dimages .product__options .option__kleur-deuren,
  html.dshops--3dimages .product__options .option__deur-kleur,
  html.dshops--3dimages .product__options .option__linkerdeur,
  html.dshops--3dimages .product__options .option__rechterdeur,
  html.dshops--3dimages .product__options .option__dwarsbanen-deuren,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur,
  html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven,
  html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen,
  html.dshops--3dimages .product__options .option__kleur,
  html.dshops--3dimages .product__options .option__kastromp,
  html.dshops--3dimages .product__options .option__deuren,
  html.dshops--3dimages .product__options .option__glas-op-deuren {
    margin-bottom: 20px;
  }
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container,
html.dshops--3dimages .product__options .option__linkerdeur .dd-container,
html.dshops--3dimages .product__options .option__rechterdeur .dd-container,
html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container,
html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container,
html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container,
html.dshops--3dimages .product__options .option__kleur .dd-container,
html.dshops--3dimages .product__options .option__kastromp .dd-container,
html.dshops--3dimages .product__options .option__deuren .dd-container,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container {
  position: relative;
  z-index: 9;
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-select,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-select,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-select,
html.dshops--3dimages .product__options .option__linkerdeur .dd-container .dd-select,
html.dshops--3dimages .product__options .option__rechterdeur .dd-container .dd-select,
html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container .dd-select,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-select,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-select,
html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container .dd-select,
html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container .dd-select,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-select,
html.dshops--3dimages .product__options .option__kastromp .dd-container .dd-select,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-select,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-select {
  display: none;
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options,
html.dshops--3dimages .product__options .option__linkerdeur .dd-container .dd-options,
html.dshops--3dimages .product__options .option__rechterdeur .dd-container .dd-options,
html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container .dd-options,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options,
html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container .dd-options,
html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container .dd-options,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options,
html.dshops--3dimages .product__options .option__kastromp .dd-container .dd-options,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options {
  display: block !important;
  position: inherit !important;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__linkerdeur .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__rechterdeur .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__kastromp .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li {
  display: inline-block;
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__linkerdeur .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__rechterdeur .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__kastromp .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option {
  display: block;
  padding: 0;
  border: 2px solid #ccc;
  margin: 0 5px 5px 0;
  border-radius: 3px;
}
@media (min-width: 320px) and (max-width: 374px) {
  html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__linkerdeur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__rechterdeur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kastromp .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option {
    margin: 0 5px 5px 0;
  }
}
@media (min-width: 375px) and (max-width: 420px) {
  html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__linkerdeur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__rechterdeur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__kastromp .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option,
  html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option {
    margin: 0 6px 4px 0;
  }
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__linkerdeur .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__rechterdeur .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__kastromp .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option.dd-option-selected,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option.dd-option-selected {
  border: 2px solid #75b325;
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__linkerdeur .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__rechterdeur .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__kastromp .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option .dd-option-image,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option .dd-option-image {
  float: none;
  max-width: 48px;
  width: 100%;
  margin-right: 0;
}
@media (min-width: 320px) and (max-width: 379px) {
  html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__linkerdeur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__rechterdeur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kastromp .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option .dd-option-image {
    max-width: 30px;
    width: 100%;
  }
}
@media (min-width: 380px) and (max-width: 480px) {
  html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__linkerdeur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__rechterdeur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__kastromp .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option .dd-option-image,
  html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option .dd-option-image {
    max-width: 39px;
    width: 100%;
  }
}
html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__linkerdeur .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__rechterdeur .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__kastromp .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li .dd-option .dd-option-text,
html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li .dd-option .dd-option-text {
  display: none;
}
@media (min-width: 375px) and (max-width: 420px) {
  html.dshops--3dimages .product__options .option__kast-kleur .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__kleur-deuren .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__deur-kleur .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__linkerdeur .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__rechterdeur .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__dwarsbanen-deuren .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__kleur-linkerdeur .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__kleur-rechterdeur .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__deurpanelen-onder-en-boven .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__kleur-kast-en-handgrepen .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__kleur .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__kastromp .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__deuren .dd-container .dd-options li:last-child .dd-option,
  html.dshops--3dimages .product__options .option__glas-op-deuren .dd-container .dd-options li:last-child .dd-option {
    margin-right: 0;
  }
}

@media (max-width: 575px) {
  html:not(.dshops--3dimages) .webshop-product__option .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }
  html:not(.dshops--3dimages) .webshop-product__option .col-3 label {
    margin-bottom: 0 !important;
  }
  html:not(.dshops--3dimages) .webshop-product__option .col-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.productpage__nav__container {
  display: none;
}
.productpage__nav__container .btn {
  border-radius: 0.25rem;
}
.productpage__nav__container .btn i {
  font-size: 18px;
}

body.scroll-down .productpage__nav__container {
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1918px;
  z-index: 10000;
  transform: none;
}
body.scroll-down .productpage__nav__container + .container {
  margin-top: 0px;
}
@media (max-width: 767px) {
  body.scroll-down .productpage__nav__container {
    top: inherit;
    bottom: 0;
    margin-bottom: 0;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
body.scroll-down #sidebar {
  position: sticky;
  top: 70px;
}

body.scroll-up .productpage__nav__container {
  transform: translate3d(0, -100px, 0);
}
@media (max-width: 767px) {
  body.scroll-up .productpage__nav__container {
    margin-bottom: -65px;
  }
}
body.scroll-up #sidebar {
  position: sticky;
  top: 145px;
}

html:not(.webshop--isproductpage) .hideonproductpage {
  display: block !important;
}
@media (max-width: 767px) {
  html:not(.webshop--isproductpage) .hideonproductpage {
    display: none !important;
  }
}
html:not(.webshop--isproductpage) .showonproductpage {
  display: none !important;
}

html.webshop--isproductpage .hideonproductpage {
  display: none !important;
}
html.webshop--isproductpage .showonproductpage {
  display: block !important;
}
@media (max-width: 767px) {
  html.webshop--isproductpage .showonproductpage {
    display: none !important;
  }
}
@media (max-width: 767px) {
  html.webshop--isproductpage body {
    padding-bottom: 65px;
  }
  html.webshop--isproductpage body.scroll-down .TrengoWidgetLauncher__iframe {
    bottom: 45px;
  }
}

.webshop--isproductpage .breadcrumb {
  padding-left: 12px;
  padding-top: 10px !important;
  margin-bottom: 10px;
}
.webshop--isproductpage .breadcrumb li:first-child {
  display: none;
}
.webshop--isproductpage main > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .webshop--isproductpage .button_faf {
    display: none;
  }
}
.webshop--isproductpage .productpage__nav__container {
  transform: translate3d(0, -120px, 0);
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  transition: all ease 0.4s;
}
.webshop--isproductpage .productpage__nav__container .jivo-btn {
  display: none;
}
@media (max-width: 767px) {
  .webshop--isproductpage .productpage__nav__container {
    transform: translate3d(0, 0, 0);
    bottom: -85px;
    opacity: 0;
  }
  .webshop--isproductpage .productpage__nav__container .jivo-btn {
    display: inline-block;
  }
  .webshop--isproductpage .productpage__nav__container .jivo-btn .fa-stack {
    width: 16px;
    height: 20px;
  }
  .webshop--isproductpage .productpage__nav__container .jivo-btn .fa-stack .fa-comment {
    font-size: 24px;
    line-height: 16px;
    position: relative;
    left: -4px;
    top: -3px;
  }
  .webshop--isproductpage .productpage__nav__container .jivo-btn .fa-stack .fa-question {
    color: #60a5c4;
    font-size: 18px;
    line-height: 16px;
  }
}
.webshop--isproductpage .productpage__nav__container .productpage__nav .list-inline {
  overflow: hidden;
}
.webshop--isproductpage .productpage__nav__container .productpage__nav .list-inline .list-inline-item a {
  padding: 0.5rem;
  color: #807d79;
  font-size: 14px;
}
.webshop--isproductpage .productpage__nav__container .productpage__nav__cart {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 5px;
  line-height: 18px;
}
.webshop--isproductpage .productpage__nav__container .productpage__nav__cart .product__listprice {
  position: relative;
  color: #a0a8ab;
}
.webshop--isproductpage .productpage__nav__container .productpage__nav__cart .product__listprice:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #a0a8ab;
  border-radius: 0;
  transform: rotate(-11deg);
}
.webshop--isproductpage .productpage__nav__container .productpage__nav__cart .product__price {
  font-size: 21px;
  font-weight: 600;
}
@media (max-width: 565px) {
  .webshop--isproductpage .productpage__nav__container .productpage__nav__cart {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .webshop--isproductpage .productpage__nav__container .productpage__nav__cart {
    border-right: none;
    border-left: none;
    padding: 10px 5px;
  }
}
@media (max-width: 992px) {
  .webshop--isproductpage .productpage__nav__container {
    font-size: 15px;
    border-top: 1px solid #ccc;
  }
}
.webshop--isproductpage .productpage__nav__container + .container {
  margin-top: -60px;
}
@media (min-width: 767px) {
  .webshop--isproductpage .productpage__nav__container + .container {
    margin-top: -40px;
  }
}
@media (min-width: 992px) {
  .webshop--isproductpage .productpage__nav__container + .container {
    margin-top: -50px;
  }
}
.webshop--isproductpage .productpage__header__container {
  background: url(../../web/img/golven-productpagina.svg) no-repeat bottom center #f7fbfe;
  padding-bottom: 80px;
}
@media (max-width: 565px) {
  .webshop--isproductpage .productpage__header__container > .container {
    padding-left: 0;
    padding-right: 0;
  }
  .webshop--isproductpage .productpage__header__container .product__options {
    --productoptions-padding: var(--bs-gutter-x,.75rem);
  }
  .webshop--isproductpage .productpage__header__container .product_promoimage {
    padding-left: var(--productoptions-padding);
    padding-right: var(--productoptions-padding);
  }
}
.webshop--isproductpage .webshop--isoutofstock h2,
.webshop--isproductpage .webshop--isoutofstock .webshop-product__options {
  display: none;
}
.webshop--isproductpage .webshop--isoutofstock .webshop-product__add:hover {
  cursor: not-allowed;
}
.webshop--isproductpage .show-if-no-stock, .webshop--isproductpage .show-if-in-stock {
  display: none;
}
.webshop--isproductpage webshop-product[data-webshop-in-stock]:not([data-webshop-in-stock="0"]) .show-if-in-stock,
.webshop--isproductpage webshop-product:not([data-webshop-in-stock]) .show-if-no-stock,
.webshop--isproductpage webshop-product[data-webshop-in-stock="0"] .show-if-no-stock,
.webshop--isproductpage .stock-message {
  display: unset;
}
.webshop--isproductpage webshop-product[data-webshop-in-stock] .skw-instocktext {
  height: 20px;
  margin-top: 5px;
  text-align: center;
}
.webshop--isproductpage webshop-product[data-webshop-in-stock] .skw-instocktext:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c"; /* fa-check */
  color: #75b325;
  line-height: 12px;
  font-weight: 700;
  font-size: 16px;
  margin-right: 5px;
}
.webshop--isproductpage webshop-product[data-webshop-in-stock].skw-instocktext:after {
  display: block;
  line-height: 12px;
  font-weight: 700;
  font-size: 13px;
  position: absolute;
}
.webshop--isproductpage webshop-product[data-webshop-in-stock]:not(.webshop--isalwaysorderable) .skw-instocktext:after {
  content: "Uit voorraad leverbaar";
}
.webshop--isproductpage webshop-product[data-webshop-in-stock]:not(.webshop--isalwaysorderable)[data-webshop-in-stock="1"] .skw-instocktext:after {
  content: "Nog 1 op voorraad, OP = OP!";
}
.webshop--isproductpage webshop-product[data-webshop-in-stock]:not(.webshop--isalwaysorderable)[data-webshop-in-stock="2"] .skw-instocktext:after {
  content: "Nog 2 op voorraad, OP = OP!";
}
.webshop--isproductpage webshop-product[data-webshop-in-stock]:not(.webshop--isalwaysorderable)[data-webshop-in-stock="3"] .skw-instocktext:after {
  content: "Nog 3 op voorraad, OP = OP!";
}
.webshop--isproductpage webshop-product[data-webshop-in-stock].webshop--isalwaysorderable .skw-instocktext:after {
  content: "Uit voorraad leverbaar";
}
.webshop--isproductpage webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="1"] .skw-instocktext, .webshop--isproductpage webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="2"] .skw-instocktext, .webshop--isproductpage webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="3"] .skw-instocktext {
  display: block;
}
.webshop--isproductpage webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="1"] .skw-instocktext:after {
  content: "Nog 1 op voorraad";
}
.webshop--isproductpage webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="2"] .skw-instocktext:after {
  content: "Nog 2 op voorraad";
}
.webshop--isproductpage webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="3"] .skw-instocktext:after {
  content: "Nog 3 op voorraad";
}
.webshop--isproductpage webshop-product[data-webshop-in-stock].webshop--isalwaysorderable[data-webshop-in-stock="0"] .skw-instocktext {
  display: none;
}
.webshop--isproductpage .plus_circle {
  margin-left: 30px;
}
.webshop--isproductpage .plus_circle .fa-plus-circle {
  color: #75b325;
}
.webshop--isproductpage #product__images__preview__container,
.webshop--isproductpage #product__images__preview__container2 {
  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  max-height: 600px;
}
.webshop--isproductpage #product__images__preview__container .product__images__preview,
.webshop--isproductpage #product__images__preview__container2 .product__images__preview {
  overflow: hidden;
  background-color: #fff;
}
@media (max-width: 992px) {
  .webshop--isproductpage #product__images__preview__container,
  .webshop--isproductpage #product__images__preview__container2 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
@media (min-width: 1200px) {
  .webshop--isproductpage #product__images__preview__container .slick-prev,
  .webshop--isproductpage #product__images__preview__container .slick-next,
  .webshop--isproductpage #product__images__preview__container2 .slick-prev,
  .webshop--isproductpage #product__images__preview__container2 .slick-next {
    opacity: 0;
    transition: all 0.5s ease;
  }
  .webshop--isproductpage #product__images__preview__container:hover .slick-prev,
  .webshop--isproductpage #product__images__preview__container:hover .slick-next,
  .webshop--isproductpage #product__images__preview__container2:hover .slick-prev,
  .webshop--isproductpage #product__images__preview__container2:hover .slick-next {
    opacity: 1;
  }
}
.webshop--isproductpage .product__image img {
  max-height: 100px;
  width: auto;
}
@media (max-width: 1200px) {
  .webshop--isproductpage .product__image img {
    max-height: 70px;
  }
}
@media (max-width: 767px) {
  .webshop--isproductpage .product__image img {
    max-height: 54px;
  }
}
.webshop--isproductpage .product__image:hover {
  cursor: pointer;
}
.webshop--isproductpage .slick-prev {
  left: -1px;
  z-index: 1;
  height: 30%;
  background-color: rgba(0, 0, 0, 0.7);
  width: 40px;
  border-radius: 0 50px 50px 0;
}
.webshop--isproductpage .slick-prev:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f104"; /* fa-angle-left */
  color: #fff;
}
.webshop--isproductpage .slick-next {
  right: 0;
  z-index: 1;
  height: 30%;
  background-color: rgba(0, 0, 0, 0.7);
  width: 40px;
  border-radius: 50px 0 0 50px;
}
.webshop--isproductpage .slick-next:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f105"; /* fa-angle-right */
  color: #fff;
}
.webshop--isproductpage .product__images {
  position: relative;
}
.webshop--isproductpage .product__images .badge-danger {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: var(--salebadge-color);
  border-radius: 0;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 6px 12px;
}
@media (max-width: 480px) {
  .webshop--isproductpage .product__images .badge-danger {
    font-size: 1em;
  }
}
@media (max-width: 992px) {
  .webshop--isproductpage .product__images {
    padding: 0;
  }
}
.webshop--isproductpage .product__images__grid,
.webshop--isproductpage .product__3dimages__grid {
  padding: 20px;
  max-height: 140px;
  margin-top: -5px;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
}
.webshop--isproductpage .product__images__grid .slick-slide,
.webshop--isproductpage .product__3dimages__grid .slick-slide {
  margin-right: 10px;
  opacity: 0.6;
}
.webshop--isproductpage .product__images__grid .slick-slide.slick-current,
.webshop--isproductpage .product__3dimages__grid .slick-slide.slick-current {
  opacity: 1;
}
.webshop--isproductpage .product__images__grid .slick-slide.slick-current img,
.webshop--isproductpage .product__3dimages__grid .slick-slide.slick-current img {
  border: 2px solid #807d79;
}
.webshop--isproductpage .product__3dimages__grid .slick-slide.slick-current img {
  border: none;
}
.webshop--isproductpage .slick-dotted.slick-slider {
  margin-bottom: 40px;
}
.webshop--isproductpage .product_social_sharing,
.webshop--isproductpage .product_social_sharing-kt {
  text-align: center;
  padding: 18px 0;
}
.webshop--isproductpage .product_social_sharing .mx-auto,
.webshop--isproductpage .product_social_sharing-kt .mx-auto {
  width: 255px;
}
.webshop--isproductpage .product_social_sharing i,
.webshop--isproductpage .product_social_sharing-kt i {
  font-size: 21px;
  margin-left: 10px;
}
.webshop--isproductpage .product_social_sharing i.fa-whatsapp,
.webshop--isproductpage .product_social_sharing-kt i.fa-whatsapp {
  color: #75b325;
}
.webshop--isproductpage .product_social_sharing i.fa-pinterest,
.webshop--isproductpage .product_social_sharing-kt i.fa-pinterest {
  color: #de4646;
}
.webshop--isproductpage .product_social_sharing i.fa-envelope-o,
.webshop--isproductpage .product_social_sharing-kt i.fa-envelope-o {
  color: #807d79;
}
.webshop--isproductpage .product_social_sharing-kt {
  border: none;
  margin-bottom: 0;
  padding: 0;
}
.webshop--isproductpage .webshop-product__options {
  margin-bottom: 5px;
}
@media (max-width: 575px) {
  .webshop--isproductpage .webshop-product__options .col-9 {
    margin-bottom: 5px;
  }
}
.webshop--isproductpage .product__options {
  border-radius: 6px;
  background-color: #fff;
}
.webshop--isproductpage .product__options .option__binnenkleur,
.webshop--isproductpage .product__options .option__interieur-kleur {
  display: none;
}
.webshop--isproductpage .product__options__container {
  position: relative;
}
.webshop--isproductpage .product__options .dd-select {
  border: 1px solid #ddd;
}
.webshop--isproductpage .product__options .webshop-product__options .webshop-product__option {
  margin-bottom: 5px;
}
@media (max-width: 480px) {
  .webshop--isproductpage .product__options .webshop-product__options .webshop-product__option {
    margin-bottom: 2px;
  }
}
@media (max-width: 480px) {
  .webshop--isproductpage .product__options .webshop-product__options .col-3 {
    padding-right: 0;
  }
}
.webshop--isproductpage .product__options .webshop-product__options .col-3 label {
  font-size: 12px;
  line-height: 18px !important;
  margin-bottom: 0;
}
@media (max-width: 375px) {
  .webshop--isproductpage .product__options .webshop-product__options .col-3 label {
    word-wrap: break-word;
  }
}
.webshop--isproductpage .product__options .webshop-product__options .dd-option-image {
  width: 20%;
  margin-right: 2%;
  float: left;
}
.webshop--isproductpage .product__options .webshop-product__options .col-9 label {
  line-height: 20px !important;
  width: 78%;
  float: left;
  font-size: 13px;
}
.webshop--isproductpage .product__options .webshop-product__options .product__option {
  height: 40px;
}
@media (max-width: 992px) {
  .webshop--isproductpage .product__options #product__buttons {
    width: 100%;
    float: none;
  }
}
.webshop--isproductpage .product__options__footer {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
}
.webshop--isproductpage .product__options__footer .checkmarks img {
  margin-left: 5px;
  margin-top: -5px;
  max-width: 90px;
}
.webshop--isproductpage .product__discountslogan {
  color: #75b325;
  margin-top: 15px;
  font-size: 0.875em;
  font-weight: 600;
}

html.kt-configurator .tabs_container {
  margin-top: 30px;
}

#kt-configurator,
#waterbed-config {
  position: relative;
}
#kt-configurator p, #kt-configurator .promoimages,
#waterbed-config p,
#waterbed-config .promoimages {
  padding-top: 5px;
}

#waterbed-config {
  margin-top: 20px;
}
#waterbed-config:before {
  left: 0;
}
#waterbed-config:after {
  right: 0;
}
#waterbed-config .carousel-item > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
#waterbed-config .carousel-indicators {
  position: relative;
  bottom: 0;
  z-index: 9;
  width: 100%;
  top: 20px;
  margin: 5px 0;
  left: -10px;
}
#waterbed-config .carousel-indicators > div {
  text-indent: 0;
  width: 20%;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
  position: relative;
  height: auto;
}
#waterbed-config .carousel-indicators > div:before {
  display: none;
}
#waterbed-config .carousel-indicators > div:after {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
  top: 0;
  height: auto;
}
#waterbed-config .carousel-indicators > div > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#waterbed-config .carousel-indicators > div:hover, #waterbed-config .carousel-indicators > div.active {
  opacity: 1;
}
@media (max-width: 991px) {
  #waterbed-config .carousel-indicators {
    display: none;
  }
}
#waterbed-config .carousel-indicators [data-bs-target] {
  text-indent: 0;
  width: 90px;
}
#waterbed-config .carousel-indicators [data-bs-target] img {
  width: 100%;
}
#waterbed-config .carousel-control-prev {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001));
  background-repeat: repeat-x;
}
#waterbed-config .carousel-control-next {
  left: auto;
  right: 0;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5));
  background-repeat: repeat-x;
}
#waterbed-config .carousel-text {
  position: absolute;
  left: 15px;
  right: 15px;
  height: 26px;
  bottom: 5px;
  font-size: 21px;
}
#waterbed-config > .contentbox {
  margin-top: 30px;
  background-color: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}
#waterbed-config > .contentbox > div {
  padding: 10px 15px;
}
#waterbed-config > .contentbox > div.choices {
  padding-bottom: 20px;
}
#waterbed-config .waterbed-config__monoorduo button,
#waterbed-config .waterbed-config__stabilisationchoice label {
  background-color: #fff;
  border-color: #584a45;
  color: #584a45;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border: 1px solid #7a645a;
  border-radius: 4px;
  transition: all 0s;
}
#waterbed-config .waterbed-config__monoorduo button:focus,
#waterbed-config .waterbed-config__stabilisationchoice label:focus {
  box-shadow: none;
}
#waterbed-config .waterbed-config__monoorduo button:hover,
#waterbed-config .waterbed-config__stabilisationchoice label:hover {
  background-color: #e6e6e6;
  background-position: 0 -15px;
}
#waterbed-config.waterbed-config--mono button.waterbed-config__selectmono, #waterbed-config.waterbed-config--duo button.waterbed-config__selectduo,
#waterbed-config input[type=radio]:checked + label {
  background-color: #82cb25;
  border-color: #82cb25;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #77ba22;
  border-color: #66a01d #66a01d #3d5f11;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}
#waterbed-config.waterbed-config--mono button.waterbed-config__selectmono:hover, #waterbed-config.waterbed-config--duo button.waterbed-config__selectduo:hover,
#waterbed-config input[type=radio]:checked + label:hover {
  background-color: #66a01d;
  background-position: 0 -15px;
}
#waterbed-config .heading {
  margin: 0;
  padding: 10px 15px;
  color: #584a45;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}
#waterbed-config .waterbed-config__stabilisationchoice:after {
  display: block;
  content: "";
  clear: both;
}
#waterbed-config .waterbed-config__stabilisationchoice h3 {
  margin-bottom: 0;
}
#waterbed-config .waterbed-config__stabilisationchoice ul {
  list-style-type: none;
  margin: 0 0 0 -15px;
  padding: 0;
}
#waterbed-config .waterbed-config__stabilisationchoice ul > li {
  display: inline-block;
  padding-top: 10px;
  padding-left: 15px;
  vertical-align: top;
}
#waterbed-config .waterbed-config__stabilisationchoice label {
  vertical-align: top;
  padding: 0 40px;
  display: flex;
  min-height: 70px;
  align-self: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
  font-size: 18px;
  margin: 0;
  cursor: pointer;
}
#waterbed-config .waterbed-config__stabilisationchoice label > svg {
  position: absolute;
  width: 59px;
  height: 36px;
  top: -40px;
  left: 75px;
}
#waterbed-config .waterbed-config__stabilisationchoice label > span {
  display: inline-block;
  align-content: space-around;
  width: 100%;
  text-align: center;
}
#waterbed-config .waterbed-config__stabilisationchoice label > span.note {
  font-size: 13px;
}
#waterbed-config .waterbed-config__stabilisationchoice .mostchosen label {
  padding: 0 20px;
  border-color: #66a01d;
  border-width: 2px;
}
#waterbed-config .waterbed-config__stabilisationchoice input {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
}
#waterbed-config .waterbed-config__stabilisationchoice > .choices__left {
  float: left;
  width: 50%;
}
#waterbed-config .waterbed-config__stabilisationchoice > .choices__right {
  float: right;
  width: 50%;
}
#waterbed-config .description:after {
  clear: both;
  content: "";
  display: block;
}
#waterbed-config .description__left, #waterbed-config .description__right {
  width: 50%;
  max-width: 445px;
}
#waterbed-config .description__left {
  float: left;
}
#waterbed-config .description__right {
  float: right;
}
@media (max-width: 991px) {
  #waterbed-config .waterbed-config__stabilisationchoice label {
    padding: 0 30px;
  }
  #waterbed-config .waterbed-config__stabilisationchoice .mostchosen label {
    padding: 0 0;
  }
  #waterbed-config .waterbed-config__stabilisationchoice > .choices__left,
  #waterbed-config .waterbed-config__stabilisationchoice > .choices__right,
  #waterbed-config .description__left,
  #waterbed-config .description__right {
    float: none;
    width: 100%;
    max-width: 100%;
  }
  #waterbed-config .waterbed-config__stabilisationchoice > .choices__right {
    margin-top: 20px;
  }
}
@media (max-width: 400px) {
  #waterbed-config .waterbed-config__stabilisationchoice label > svg {
    display: none;
  }
}
#waterbed-config .waterbed-config__optionlist {
  margin-bottom: 20px;
}
#waterbed-config .waterbed-config__optionlist .table thead th {
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 0 none;
  border-color: #ddd;
  padding-left: 0;
}
#waterbed-config .waterbed-config__optionlist tbody > tr:nth-child(2n) {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
#waterbed-config .waterbed-config__review {
  margin: 20px 0;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  position: relative;
}
#waterbed-config .waterbed-config__review .mm-recensie {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #75bcd6;
  color: #fff;
  font-size: 23px;
  text-align: center;
  padding-top: 10px;
  float: left;
}
#waterbed-config .waterbed-config__review .mm-recensie > span {
  display: block;
}
#waterbed-config .waterbed-config__review .mm-recensie > span + span {
  font-size: 12px;
}
#waterbed-config .waterbed-config__review .rating-stars-count {
  margin-top: 15px;
}
#waterbed-config .waterbed-config__review .rating-stars-count a {
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
  color: #584a45;
}
#waterbed-config .waterbed-config__review .keurmerk {
  display: block;
  text-decoration: none;
  width: 120px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 20px;
}
#waterbed-config .waterbed-config__review .keurmerk > img {
  width: 100%;
  height: auto;
}
#waterbed-config .waterbed-config__totalprice {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  padding: 20px;
  font-size: 21px;
  margin: 20px 0;
}
#waterbed-config .waterbed-config__totalprice .webshop-product__price {
  font-size: inherit;
}
#waterbed-config .waterbed-config__totalprice .webshop-product__price:before {
  content: "€";
  display: inline-block;
  padding-right: 5px;
}
#waterbed-config .waterbed-config__totalprice button {
  margin-top: 10px;
  width: auto;
  display: inline-block;
}
#waterbed-config .waterbed-config__images {
  border: 1px solid #ddd;
  padding: 4px;
}
#waterbed-config .waterbed-config__images h1 {
  padding: 10px;
}
#waterbed-config button.waterbed-config__selectmono,
#waterbed-config button.waterbed-config__selectduo {
  padding: 20px 40px;
}
#waterbed-config .choices {
  background-color: #f5f5f5;
}
#waterbed-config .choices button + button {
  margin-left: 15px;
}

html.kt-configurator .hideon-kt-configurator,
html.waterbed-config .hideon-kt-configurator {
  display: none;
}

.showon-kt-configurator {
  display: none;
}

html.kt-configurator .showon-kt-configurator,
html.waterbed-config .showon-kt-configurator {
  display: inline-block;
}

.product_promoimage > img {
  max-width: 100%;
  height: auto;
}

.product__options #btn-kt-configurator,
.product__options .webshop-product__add,
.product__options #btn-waterbed-configurator {
  font-size: 16px;
}

@media (max-width: 480px) {
  .product__options #btn-kt-configurator {
    font-size: 12px;
    padding: 0.7rem;
  }
}

.webshop-product__optiontitle {
  float: left;
  width: 100px;
}

.dd-container {
  position: relative;
  display: inline-block;
}

@media (max-width: 992px) {
  .dd-selected {
    padding: 3px !important;
  }
}
.dd-selected-text,
.webshop-product__optiontitle {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 13px;
}

.product__tags {
  margin-top: 20px;
  margin-bottom: 0 !important;
  overflow: hidden;
  height: 180px;
}
.product__tags.show {
  height: auto !important;
}
.product__tags .product__tag {
  background: #f7fbfe;
  color: #6dbade;
  border-radius: 50rem;
  font-size: 14px;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px 5px 0;
  padding: 5px 15px;
  transition: all 0.2s ease;
}
.product__tags .product__tag:hover {
  background: #6dbade;
  color: #f7fbfe;
  text-decoration: none;
}
@media (max-width: 767px) {
  .product__tags {
    height: 150px;
    overflow: hidden;
  }
  .product__tags .product__tag {
    font-size: 12px;
    padding: 5px 10px;
  }
}

#showmoretags {
  color: #6dbade;
  width: fit-content;
}
#showmoretags:after {
  content: "Toon meer";
}
#showmoretags:before {
  content: "\f107"; /* fa-angle-down */
  font-family: "Font Awesome 5 Pro";
}
#showmoretags.clicked:after {
  content: "Toon minder";
}
#showmoretags.clicked:before {
  content: "\f106"; /* fa-angle-up */
  font-family: "Font Awesome 5 Pro";
}

#product__tabs .tabs_container #nav-tabContent {
  clear: both;
}
#product__tabs .tabs_container .nav-pills_container {
  margin: -20px 0 30px 0;
}
#product__tabs .tabs_container .product__description .widget-collapse .card-header,
#product__tabs .tabs_container .product__description .widget-faq .card-header {
  display: block;
}
#product__tabs .tabs_container .product__description .widget-collapse .collapse,
#product__tabs .tabs_container .product__description .widget-faq .collapse {
  display: none;
}
#product__tabs .tabs_container .product__description .widget-collapse .collapse.show,
#product__tabs .tabs_container .product__description .widget-faq .collapse.show {
  display: block;
}
#product__tabs .tabs_container .nav-pills {
  display: none;
}
#product__tabs .tabs_container .nav-pills .nav-link {
  padding: 0.6rem 1rem;
  margin-right: 1rem;
  border-radius: 50rem;
  border: 1px solid #ddd;
  color: #75bcd6;
}
#product__tabs .tabs_container .nav-pills .nav-link:hover {
  background: #f7fbfe;
}
#product__tabs .tabs_container .nav-pills .nav-link.active {
  color: #fff;
  background: #75bcd6;
  border: 1px solid #75bcd6;
}
@media (min-width: 768px) {
  #product__tabs .tabs_container .nav-pills {
    display: flex;
  }
  #product__tabs .tabs_container .card {
    border: none;
  }
  #product__tabs .tabs_container .card.tab-pane {
    display: block !important;
  }
  #product__tabs .tabs_container .card .card-header {
    display: none;
  }
  #product__tabs .tabs_container .card .collapse {
    display: block;
  }
}
@media (max-width: 767px) {
  #product__tabs .tabs_container {
    border-bottom: none;
    background-color: #fff;
  }
  #product__tabs .tabs_container .tab-pane {
    display: block !important;
    opacity: 1;
  }
  #product__tabs .tabs_container .nav-pills_container {
    border: 0;
    display: none;
  }
  #product__tabs .tabs_container .card {
    border: none;
    border-radius: 0;
  }
  #product__tabs .tabs_container .card .card-header {
    background-color: #fff;
  }
  #product__tabs .tabs_container .card .card-header h5 {
    font-size: 16px;
  }
  #product__tabs .tabs_container .card .card-header h5 a {
    padding: 0.75rem 1.25rem;
    display: block;
  }
  #product__tabs .tabs_container .card .card-header h5 a:not(.collapsed):after {
    content: "\f106"; /* fa-angle-up */
    font-size: 16px;
    font-family: "Font Awesome 5 Pro";
    float: right;
  }
  #product__tabs .tabs_container .card .card-header h5 a.collapsed:after {
    content: "\f107"; /* fa-angle-down */
    font-size: 16px;
    font-family: "Font Awesome 5 Pro";
    float: right;
  }
  #product__tabs .tabs_container .container {
    padding: 0;
  }
  #product__tabs .tabs_container .nav .nav-item.active {
    background-color: transparent;
    border: none;
  }
  #product__tabs .tabs_container .nav .nav-item .nav-link {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
  }
  #product__tabs .tabs_container .nav .nav-item .nav-link:after {
    font-size: 16px;
    font-family: "Font Awesome 5 Pro";
    content: "\f105"; /* fa-angle-right */
    float: right;
  }
}
@media (max-width: 992px) {
  #product__tabs {
    background-color: #fff;
  }
}

.producttab__images .producttab__image img {
  border-right: 5px solid #fff;
}

.product__related {
  margin-top: 15px;
  padding: 50px 0;
}
@media (min-width: 768px) {
  .product__related {
    background: linear-gradient(to bottom, rgba(221, 221, 221, 0) 0%, rgba(221, 221, 221, 0.5) 100%);
  }
}
.product__related .productsgrid {
  margin: 0;
  width: 100%;
}
.product__related h2 {
  margin-bottom: 30px;
}
.product__related .productitem__body {
  padding: 15px;
}
.product__related .productitem__footer {
  border-bottom: 0;
}
.product__related .productitem__footer .productitem__price {
  min-height: inherit;
}
.product__related .productitem__footer .productitem__message,
.product__related .productitem__footer .productitem__discountslogan {
  display: none;
}
.product__related .productitem__footer .btn {
  top: 0;
}
@media (max-width: 767px) {
  .product__related {
    padding: 0;
    margin-bottom: 30px;
    background-color: transparent;
  }
  .product__related h2 {
    margin-bottom: 10px;
  }
  .product__related .card-body {
    padding: 0;
  }
  .product__related .container {
    padding: 0;
  }
}

.product__orderinfo,
.product__reviews {
  padding: 50px 0;
}
@media (max-width: 767px) {
  .product__orderinfo,
  .product__reviews {
    padding: 0;
  }
}

.product__specs,
.producttab__images,
.product__documents {
  padding: 50px 0;
}
@media (max-width: 767px) {
  .product__specs,
  .producttab__images,
  .product__documents {
    padding: 0;
  }
}
.product__specs h2,
.producttab__images h2,
.product__documents h2 {
  margin-bottom: 30px;
}

.product__spectablerow:nth-of-type(odd) {
  background-color: #f7fbfe;
}

.webshop-product__listprice__prefix {
  color: #807d79;
}

.webshop-product__listprice {
  font-size: 19px;
  font-weight: 400;
  position: relative;
  color: #807d79;
}
.webshop-product__listprice:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 2px solid #595957;
  border-radius: 0;
  transform: rotate(-11deg);
}

.webshop-product__price {
  font-size: 42px;
  font-weight: 600;
  font-family: "Rosario", sans-serif;
  color: #5a5a58;
}

#popup-in3 {
  color: #74c8ef;
  cursor: pointer;
  top: 0;
  position: relative;
  font-size: 18px;
}

.skw-product__in3 {
  display: inherit;
}

/*
  Hide <select> once the replacement .dd-container is live
*/
.dd-container + select {
  display: none;
}

.dd-selected {
  position: relative;
  font-style: normal;
}
.dd-selected label {
  line-height: 24px !important;
}

@media (max-width: 992px) {
  .webshop--isproductpage {
    --productoptions-padding: 15px;
  }
  .webshop--isproductpage .product__images {
    overflow: hidden;
    background-color: #fff;
  }
  .webshop--isproductpage .product__options__column {
    background-color: #f7fbfe;
  }
  .webshop--isproductpage .product__options__column ~ .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .webshop--isproductpage .webshop-product__title {
    margin: 10px 0 15px 0;
    font-size: 24px;
  }
  .webshop--isproductpage .product__options {
    background-color: #fff;
    border: none;
    margin: 10px;
  }
  .webshop--isproductpage .product__options .dd-container {
    margin-bottom: 0;
  }
  .webshop--isproductpage .product__options .webshop-product__priceblock .product__isdiscount {
    background-color: transparent;
    color: inherit;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    position: relative;
  }
  .webshop--isproductpage .product__options .webshop-product__priceblock .product__isdiscount:after {
    display: none;
  }
  .webshop--isproductpage .product__options .webshop-product__priceblock .product__isdiscount .webshop-product__listprice {
    color: #a0a8ab;
  }
  .webshop--isproductpage .product__options .webshop-product__priceblock .product__isdiscount .webshop-product__listprice:before {
    border-top: 2px solid #a0a8ab;
  }
  .webshop--isproductpage .product__options .webshop-product__priceblock .product__isdiscount .productitem__discountslogan {
    color: #75b325;
    font-size: 0.875em;
    font-weight: 600;
  }
  .webshop--isproductpage #product__buttons_container {
    margin-bottom: 20px;
  }
  .webshop--isproductpage .product__options__footer {
    display: block !important;
  }
}
@media (max-width: 992px) and (max-width: 992px) {
  .webshop--isproductpage #mobile__placeholder {
    padding-bottom: 20px;
  }
}
@media (min-width: 480px) and (max-width: 979px) {
  .product__images {
    margin-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .webshop--isproductpage .product__images {
    margin-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
  .webshop--isproductpage .webshop-product__title {
    margin: 0 0 6px 0;
    font-size: 18px;
  }
  .nav-link {
    display: block;
    padding: 0.5rem;
  }
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
  .skw-product__in3 {
    font-size: 14px;
  }
}
/* waterbed configurator is initially hidden */
.waterbed-config--hidden {
  display: none;
}

.webshop-product__option--hidden,
.webshop-product__option--hiddendefault {
  display: none;
}

@media (min-width: 1200px) {
  .product__related .webshop-products-mini .productsgrid__product {
    flex: 0 0 20%;
    max-width: 20%;
  }
}