footer {
  padding-bottom: 38px; /* space for chat tab at bottom */
}
footer #ondermenu {
  padding: 50px 0 70px 0;
  margin-top: 50px;
  background: url(../web/img/golven-footer.svg) no-repeat bottom center #75bcd6;
  background-size: cover;
  color: #fff;
}
@media (max-width: 992px) {
  footer #ondermenu {
    padding: 50px 0 50px 0;
    background-size: 101%;
  }
}
footer #ondermenu .footer__widget {
  margin-bottom: 20px;
}
footer #ondermenu .footer__widget h4 {
  color: #fff;
  display: block;
  margin: 0 0 10px;
  padding: 0 0 10px;
  font-weight: 600;
}
footer #ondermenu .footer__widget a, footer #ondermenu .footer__widget h4 {
  color: #fff;
}
footer #ondermenu .footer__widget a:hover {
  color: #fff;
}
footer #ondermenu .footer__widget.widget-newsletter {
  background-color: transparent;
}
footer #ondermenu .footer__widget.widget-newsletter .img-newsletter {
  max-width: 80%;
  margin-bottom: -175px;
  transform: rotate(-4deg);
  margin-left: 30px;
  box-shadow: 0 0 26px rgba(0, 0, 0, 0.3);
}
footer #ondermenu .footer__widget.widget-newsletter .subscribeform__container {
  background-color: #75bcd6;
  padding: 25px;
  border-radius: 4px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  footer #ondermenu .footer__widget.widget-newsletter .subscribeform__container {
    padding: 15px;
  }
}
footer #ondermenu .footer__widget.widget-newsletter .subscribeform__container .form-control {
  border: none;
}
@media (min-width: 320px) and (max-width: 992px) {
  footer #ondermenu .footer__widget.widget-newsletter {
    margin-top: 100px;
  }
}
footer #ondermenu .footer__widget .footer__social {
  margin: 20px 0 0;
}
footer #ondermenu .footer__widget .footer__social i {
  font-size: 28px;
  margin-right: 10px;
}
footer #ondermenu .footer__widget ul.angles {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0 -7.5px;
}
footer #ondermenu .footer__widget ul.angles li {
  display: table-row;
}
footer #ondermenu .footer__widget ul.angles li:before {
  content: "\f105"; /* fa-angle-right */
  width: 1.5em;
  font-size: 16px;
  line-height: 28px;
  font-family: "Font Awesome 5 Pro";
  display: table-cell;
  padding-left: 7px;
  color: #fff;
}
footer #ondermenu .footer__widget .widget-customerservice {
  border: none !important;
  box-shadow: none;
  padding: 0 !important;
}
footer #ondermenu .footer__widget .widget-customerservice h4,
footer #ondermenu .footer__widget .widget-customerservice p {
  display: none;
}
footer #ondermenu .footer__widget .widget-customerservice .customerservice__buttons {
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
  flex-wrap: wrap;
}
footer #ondermenu .footer__widget .widget-customerservice .customerservice__buttons li {
  list-style: none;
  width: 100%;
  display: flex;
}
footer #ondermenu .footer__widget .widget-customerservice .customerservice__buttons a,
footer #ondermenu .footer__widget .widget-customerservice .customerservice__buttons .trengo-btn {
  display: block;
  align-items: center;
  width: 100%;
  padding: 0.5em 1em;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  margin-bottom: 10px;
  border-radius: 50rem;
  line-height: 21px;
  transition: 0.2s ease;
  cursor: pointer;
}
footer #ondermenu .footer__widget .widget-customerservice .customerservice__buttons a:hover,
footer #ondermenu .footer__widget .widget-customerservice .customerservice__buttons .trengo-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
footer #ondermenu .footer__widget .widget-customerservice .customerservice__buttons a i,
footer #ondermenu .footer__widget .widget-customerservice .customerservice__buttons .trengo-btn i {
  font-size: 24px;
  display: block;
  float: left;
  margin-bottom: 10px;
  padding-top: 10px;
  margin-right: 10px;
}
footer #ondermenu .shadow__divider {
  display: none;
}
@media (max-width: 767px) {
  footer #ondermenu {
    margin-top: 0;
  }
}

.bottomnav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 15px;
}
.bottomnav ul li {
  display: inline-block;
}
.bottomnav ul li:not(:last-child) {
  margin-right: 0;
}
.bottomnav ul li:not(:first-child):before {
  content: "-";
  padding: 0 6px;
  display: inline-block;
}

.bottom__copyright {
  margin-top: -50px;
}
@media (max-width: 992px) {
  .bottom__copyright {
    margin-top: -10px;
  }
}

.bottom__tags {
  padding: 0 0 40px 0;
  background-color: #fff;
}
.bottom__tags .row .badge-default {
  background: #f7fbfe;
  color: #6dbade;
  font-size: 14px;
  display: inline-block;
  border-radius: 50rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px 5px 0;
  padding: 10px;
  transition: all 0.2s ease;
}
.bottom__tags .row .badge-default:hover {
  background: #6dbade;
  color: #f7fbfe;
  text-decoration: none;
}
.bottom__tags .row a.badge-default {
  font-weight: 400;
}
@media (max-width: 767px) {
  .bottom__tags .row .badge-default {
    font-size: 11px;
    padding: 10px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}