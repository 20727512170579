@import url('https://fonts.googleapis.com/css2?family=Rosario:ital,wght@0,400;0,600;0,700;1,400&family=Shadows+Into+Light+Two&family=Nunito:wght@400;600;700&display=swap');
@import "@mod-slaapkamerweb/webdesigns/dshopsapp/web/fonts/fontawesome/fontawesome-cssvars.css";
@import "@mod-slaapkamerweb/web/fonts/fontawesome5/css/all.min.css";

/* light, normal, semi-bold, bold */
html
{
  color: #807d79;
  font-family: "Nunito", sans-serif;

  --contentpanel-background: transparent;
  --contentpanel-pad-h:      0px;
  --contentpanel-pad-v:      0px;
  --contentpanel-box-shadow: none;


  --divider-color:           #dee1e1;

  --component-tag-background:   #FFFFFF;
/*  --component-tag-border:       1px solid var(--divider-color);*/
  --component-tag-border:       1px solid #ced4da;
  --component-tag-font:         16px "Nunito", sans-serif;
  --component-tag-height:       56px;
  --component-tag-image-height: 38px;
  --component-tagslist-spacing: 15px;


  /* Using for
     - "Links (compact) widget" - carrousel previous / next
     - Reviews pagination
  */
/*  --navigationbutton-background:          #FFFFFF;*/


  /************************************************************
   *
   *  Page components / Pagination
   */

  --pagination-prevnext-border:           1px solid #75BCD6;
  --pagination-prevnext-spacing:          10px;
  --pagination-prevnext-background-color: #F7FBFE; /* .widget-collapse .card .card-header background-color */
  --pagination-prevnext-icon-color:       #75BCD6;

  --pagination-prevnext-hover-background: #75BCD6;
  --pagination-prevnext-hover-border:     1px solid transparent;
  --pagination-prevnext-hover-icon-color: #FFFFFF;

/*  --pagination-prevnext-hover-background: #FFFFFF;*/
  --pagination-prevnext-disabled-background: #EEEEEE;
  --pagination-prevnext-disabled-border:     1px solid transparent;
/*  --pagination-prevnext-hover-icon-color: var(--color-theme);*/

  --pagination-font:                 20px/32px var(--rtd-text-fontfamily);
  --pagination-page-link-color:      var(--color-purple-dark);
  --pagination-page-current-color:   var(--color-purple-dark);
  --pagination-page-size:            54px;
  --pagination-page-radius:           7px;
  --pagination-page-spacing:          5px;

  --pagination-page-hover-background: #F7FBFE;
}

body
{
  font-size: 16px;
  line-height: 24px;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  color: #807d79;
}

h1, h2, h3, h4, h5, h6,
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
.heading
{
    color: #5a5a58;
    font-family: "Rosario", sans-serif;
}
h1
{
    font-size: 2rem;
}
h2
{
    font-size: 1.5rem;
}
h3
{
    font-size: 1.3rem;
}
h4
{
    font-size: 1.2rem;
}
h5
{
    font-size: 1.1rem;
}
h6
{
    font-size: 1rem;
}

/* site gets this from _reboot.scss */
h1,h2,h3,h4,h5,h6
{
  margin-top: 0;
  margin-bottom: 0.5rem;
/*  font-weight: 500;*/
  line-height: 1.2;
}
p
{
  margin-top: 0;
  margin-bottom: 1rem;
}


h2.heading2_checked:before
{
  content: '\F00C'; /* fa-check */
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  color: #75b325;
  font-size: 18px;
  padding-right: 5px;
}
.handwriting
{
  font-family: 'Shadows Into Light Two', cursive;
  font-weight: normal;
  opacity: 0.7;
}
a {
    color: #75bcd6;
    text-decoration: none;
}
.text-primary
{
    color: #5a5a58!important;
}
.text-secondary
{
    color: #75bcd6!important;
}
ul.checkmarks {
    margin:0 0 10px 0;
    padding:0
}
ul.checkmarks li {
    position: relative;
    list-style-type: none;
    padding: 1px 0px 1px 35px;
}
ul.checkmarks li:before {
    content: '\f00c'; /* fa-check */
    position: absolute;
    left: 10px;
    font-family: 'Font Awesome 5 Pro';
    font-size: 21px;
    top: 2px;
    color: #75b325;
}
.fa-star
{
    color: #ffa200;
}
sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

.wh-rtd__img,
.img-fluid
{
  max-width: 100%;
  height: auto;
}
.wh-rtd__table .wh-rtd__img
{
  max-width: calc(100vw - 40px);
}
.wh-rtd__img--floatleft
{
  margin: 0 15px 15px 0;
}
.wh-rtd__img--floatright
{
  margin: 0 0 15px 15px;
}

.wh-rtd__tablewrap
{
  overflow-x: auto;
  padding-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table
{
  width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  border-top: 0;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
  color: #fff;
  padding-left: 15px;
  background-color: #584a45 !important;
}
.wh-rtd--hascolheader ~ tr > td:first-child
{
  padding-left: 15px;
}

.wh-rtd__table * + *
{
  padding-left: 15px;
}
  .wh-rtd__table th
, .wh-rtd__table td
{
  padding-top: 5px;
  padding-bottom: 5px;
}
  .wh-rtd__table th > p:last-child
, .wh-rtd__table td > p:last-child
{
  padding-bottom: 0;
}
/*
.wh-rtd__table tr + tr > td
{
  border-top: 1px solid #dadada;
}
*/
.wh-rtd__table .wh-rtd--hascolheader + tr > td
{
  border-top: 0 none;
}
.bordered-table table{
    border: 1px solid #dee2e6;
}
.bordered-table thead th {
    border-bottom: 2px solid #dee2e6;
}
.bordered-table td, .table-bordered th {
    border: 1px solid #dee2e6;
}

.widget-freeassembly
{
  border:1px solid #ccc;
  padding:20px;
  background-color: #fff;
}
.widget-freeassembly h2
{
  font-weight: 700;
  margin-bottom: 0;
}
.widget-freeassembly h3
{
  font-size: 19px;
}
.widget-freeassembly .col-lg-3 img
{
  position: relative;
  bottom: -20px;
}
.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .container
  {
    max-width: 1300px;
  }
  .d-xl-none {
    display: none !important;
  }
  .d-xl-block {
    display: block !important;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg {
        flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
        flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
}


.twocol-background .col-lg-6:nth-child(1)
{
    padding-top: 1.5rem;
}
.twocol-background .col-lg-6:nth-child(2)
{
    padding: 0;
    overflow: hidden;
    clip-path: circle(100% at 100% 40%);
    -webkit-clip-path: circle(100% at 100% 40%);
}
.twocol-background .col-lg-6:nth-child(2) img
{
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.twocol-background .col-inner-left
{
    max-width: 620px;
    float:right;
}
@media (min-width: 992px) and (max-width: 1199px)
{
  .twocol-background .col-inner-left
  {
      padding-left: 13%;
  }
  .twocol-background .col-lg-6:nth-child(2)
  {
    height: calc(49vw);
    clip-path: circle(87% at 100% 46%);
    -webkit-clip-path: circle(87% at 100% 46%);
  }
}
@media (min-width: 1199px)
{
  .twocol-background .col-lg-6:nth-child(2)
  {
    height: calc(35vw);
    clip-path: circle(78% at 78% 40%);
    -webkit-clip-path: circle(78% at 78% 40%);
  }
}

@media (min-width: 1500px)
{
  .twocol-background .col-lg-6:nth-child(2)
  {
    height: calc(30vw);
    clip-path: circle(78% at 78% 40%);
    -webkit-clip-path: circle(78% at 78% 40%);
  }
}
@media (min-width: 1800px)
{
  .twocol-background .col-lg-6:nth-child(2)
  {
    height: calc(27vw);
    max-height: 600px;
  }
}
@media (max-width: 992px)
{
  .twocol-background .col-lg-6:nth-child(2) 
  {
    height: calc(46vw);
    clip-path: unset;
    -webkit-clip-path: unset;
  }
  .twocol-background .col-inner-left
  {
    padding:0 10%;
    max-width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 768px)
{
  .twocol-background .col-lg-6:nth-child(2) 
  {
    height: calc(57vw);
    clip-path: unset;
    -webkit-clip-path: unset;
  }
  .twocol-background .col-inner-left
  {
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
  }
}
