html.webshop--ishomepage .main--margintop {
  margin-top: 0;
}
html.webshop--ishomepage .usps__skw {
  box-shadow: none;
  border: 0;
}
html.webshop--ishomepage .usps__skw .container {
  box-shadow: 0 10px 7px rgba(0, 0, 0, 0.08);
  position: relative;
  height: 45px;
}
html.webshop--ishomepage .usps__skw .container .row {
  position: relative;
  z-index: 2;
}
html.webshop--ishomepage .usps__skw .container:after {
  content: "";
  position: absolute;
  background-image: linear-gradient(to right, transparent, white);
  height: 60px;
  width: 60%;
  right: -10px;
  bottom: -30px;
  z-index: 1;
}
@media (max-width: 565px) {
  html.webshop--ishomepage .usps__skw .container:after {
    height: 36px;
    width: 30px;
    right: 0;
    bottom: -35px;
  }
}
html.webshop--ishomepage .usps__skw .container:before {
  content: "";
  position: absolute;
  background-image: linear-gradient(to left, transparent, white);
  height: 60px;
  width: 60%;
  left: -10px;
  bottom: -35px;
  z-index: 1;
}
@media (max-width: 565px) {
  html.webshop--ishomepage .usps__skw .container:before {
    height: 36px;
    width: 30px;
    left: 0;
    bottom: -35px;
  }
}
@media (max-width: 565px) {
  html.webshop--ishomepage .usps__skw .container {
    margin-bottom: 0;
  }
}
html.webshop--ishomepage .widget-twocol {
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
}
html.webshop--ishomepage .widget-twocol .row {
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 565px) {
  html.webshop--ishomepage .widget-twocol .row {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 1199px) {
  html.webshop--ishomepage .widget-twocol .row .webshop-products {
    overflow: hidden;
    max-height: 500px;
  }
}
html.webshop--ishomepage .widget-twocol .product_listing {
  max-width: 1280px;
  margin: 0 auto;
}