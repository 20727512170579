.productitem {
  display: block;
  background-color: #fff;
  height: 100%;
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font: inherit;
  color: #807d79;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease-in-out 0s;
}
.productitem .btn {
  opacity: 0;
  transition: all 0.2s ease;
}
@media (max-width: 767px) {
  .productitem .btn {
    opacity: 1;
  }
}
.productitem:hover {
  text-decoration: none;
  color: inherit;
}
.productitem:hover .btn {
  opacity: 1;
}
.productitem .badge-danger {
  color: #fff;
  background-color: var(--salebadge-color);
  position: absolute;
  left: -5px;
  top: 9px;
  border-radius: 0;
  padding: 4px 8px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 100%;
  z-index: 999;
}
.productitem__image {
  flex: none;
  /* Enforce a 7 / 5 aspect ratio for iOS < 15 (but min iOS 10.3 / iPad gen 4 2012) */
  display: grid;
  overflow: hidden;
  text-align: center;
  position: relative;
  border-radius: 6px 6px 0 0;
}
.productitem__image::before {
  content: "";
  padding-top: 71.4286%;
  grid-column: 1;
  grid-row: 1;
}
.productitem__image > * {
  grid-column: 1;
  grid-row: 1;
}
@media (min-width: 1200px) {
  .productitem__image .ambience__image:hover img:last-child {
    opacity: 1;
    visibility: visible;
  }
  .productitem__image .ambience__image:hover img:first-child {
    opacity: 0;
    visibility: hidden;
  }
  .productitem__image .ambience__image > img {
    transition: 0.2s ease all;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
  }
  .productitem__image .ambience__image > img:last-child {
    opacity: 0;
    visibility: hidden;
  }
}
@media (max-width: 1199px) {
  .productitem__image .ambience__image > img:last-child {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}
.productitem__image img {
  max-width: 100%;
  height: initial;
}
.productitem--category .productitem__image {
  aspect-ratio: 7/5;
  /* Enforce a 7 / 5 aspect ratio for iOS < 15 (but min iOS 10.3 / iPad gen 4 2012) */
  display: grid;
}
.productitem--category .productitem__image::before {
  content: "";
  padding-top: 71.4286%;
  grid-column: 1;
  grid-row: 1;
}
.productitem--category .productitem__image > * {
  grid-column: 1;
  grid-row: 1;
}
.productitem__body {
  padding: 20px 20px 0 20px;
  flex: 1 1 auto;
}
.productitem__body > span {
  display: block;
}
.productitem--category .productitem__body, .productitem.combineitem .productitem__body {
  min-height: 0;
  padding: 10px;
}
.productitem__title {
  font-size: 18px;
  font-weight: 600;
  font-family: "Rosario", sans-serif;
  color: #5a5a58;
}
.productitem__description {
  padding-top: 5px;
  font-size: 14px;
  line-height: 21px;
}
.productitem__discountslogan {
  color: #75b325;
  font-size: 0.875em;
  font-weight: 600;
  line-height: 18px;
}
.productitem__footer {
  padding: 20px;
  position: relative;
  flex: 0 1 auto;
}
.productitem__footer .btn {
  position: absolute;
  right: 10px;
  top: 20px;
}
.productitem--category .productitem__footer {
  border-top: 0 none;
  flex: 0 0 41px;
}
.productitem--category .productitem__footer .btn {
  top: 0px;
}
.productitem__price {
  min-height: 50px;
  font-family: "Rosario", sans-serif;
}
.productitem__baseprice > .price {
  display: inline-block;
  color: #a0a8ab;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  margin-right: 5px;
}
.productitem__baseprice > .price:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #a0a8ab;
  border-radius: 0;
  transform: rotate(-11deg);
}
.productitem__baseprice + .productitem__nowprice {
  padding-top: 2px;
  display: inline-block;
}
.productitem__nowprice > span {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
}
.productitem__baseprice > span.pricemultiple, .productitem__nowprice > span.pricemultiple {
  font-size: inherit;
  font-weight: inherit;
}
.productitem__message {
  padding-top: 10px;
  color: #807d79;
  font-weight: 600;
  font-size: 13px;
  line-height: initial;
  display: flex;
  align-items: center;
}
.productitem__message .icon-assemble {
  background: url(../../web/img/icon-assemble.svg) no-repeat;
  height: 25px;
  width: 25px;
  display: inline-block;
  margin-right: 5px;
}
@media (max-width: 767px) {
  .productitem--category {
    padding-left: 40%;
    min-height: 30vw;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
  .productitem--category .productitem__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    padding: 10px;
  }
  .productitem--category .productitem__body {
    padding-left: 35%;
    display: flex;
    justify-content: flex-end;
  }
  .productitem--category .productitem__body span {
    align-self: center;
    text-align: right;
  }
  .productitem--category .productitem__footer {
    border-bottom: none;
  }
}

.productitem__rating {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  transform: scale(0.9);
  transform-origin: left;
}

.productitem__rating__reviewcount {
  margin-left: 10px;
  padding-top: 5px;
}

.productitem.ribbon:before {
  width: 120px;
  height: 25px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6px 0 6px 0;
  z-index: 2;
  pointer-events: none;
}
.productitem.korting-10-png:before {
  background: url("../../web/img/ribbons/korting-10.png") no-repeat;
}
.productitem.korting-20-png:before {
  background: url("../../web/img/ribbons/korting-20.png") no-repeat;
}
.productitem.korting-25-png:before {
  background: url("../../web/img/ribbons/korting-25.png") no-repeat;
}
.productitem.korting-30-png:before {
  background: url("../../web/img/ribbons/korting-30.png") no-repeat;
}
.productitem.ribbon-sinterklaas-png:before {
  background: url("../../web/img/ribbons/ribbon-sinterklaas.png") no-repeat;
  background-size: 100px 100px;
  top: -5px;
}
.productitem.ribbon-actie-groen-png:before {
  background: url("../../web/img/ribbons/ribbon-actie-groen.png") no-repeat;
}
.productitem.ribbon-actie-png:before {
  background: url("../../web/img/ribbons/ribbon-actie.png") no-repeat;
}
.productitem.ribbon-nieuw-groen-png:before {
  background: url("../../web/img/ribbons/ribbon-nieuw-groen.png") no-repeat;
}
.productitem.ribbon-nieuw-png:before {
  background: url("../../web/img/ribbons/ribbon-nieuw.png") no-repeat;
}
.productitem.ribbon-opruiming-groen-png:before {
  background: url("../../web/img/ribbons/ribbon-opruiming-groen.png") no-repeat;
}
.productitem.ribbon-opruiming-png:before {
  background: url("../../web/img/ribbons/ribbon-opruiming.png") no-repeat;
}
.productitem.ribbon-uitverkoop-groen-png:before {
  background: url("../../web/img/ribbons/ribbon-uitverkoop-groen.png") no-repeat;
}
.productitem.ribbon-uitverkoop-png:before {
  background: url("../../web/img/ribbons/ribbon-uitverkoop.png") no-repeat;
}
.productitem.ribbon-sale-groen-png:before {
  background: url("../../web/img/ribbons/ribbon-sale-groen.png") no-repeat;
}
.productitem.ribbon-sale-png:before {
  background: url("../../web/img/ribbons/ribbon-sale.png") no-repeat;
}
.productitem.ribbon-topper-groen-png:before {
  background: url("../../web/img/ribbons/ribbon-topper-groen.png") no-repeat;
}
.productitem.ribbon-topper-png:before {
  background: url("../../web/img/ribbons/ribbon-topper.png") no-repeat;
}
.productitem.ribbon-showroom-png:before {
  background: url("../../web/img/ribbons/ribbon-showroom.png") no-repeat;
  width: 170px;
}
.productitem.ribbon-flanel-png:before {
  background: url("../../web/img/ribbons/ribbon-flanel.png") no-repeat;
  width: 195px;
}