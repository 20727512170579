#reviewform {
  background-color: #f7fbfe;
  border: 1px solid #75bcd6;
  padding: 20px;
  margin-top: 20px;
}
#reviewform .wh-form__buttongroup {
  max-width: 300px;
}
#reviewform .remark {
  border: 1px dotted #aaa;
  padding: 10px;
  margin: 40px 0 20px;
}

.reviewbtn {
  margin-bottom: 20px;
}

#showreviewstats {
  color: #337ab7;
  cursor: pointer;
}
#showreviewstats:hover {
  color: inherit;
}

#reviewpagetotals {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#reviewpagetotals .rating-stars {
  margin: 0 4px 10px 10px;
}
#reviewpagetotals .mm-rating-txt {
  line-height: 19px;
  margin-top: 0;
  margin-bottom: 10px;
}

#rating-stats {
  overflow: hidden;
  height: 0;
  line-height: 19px;
  transition: height 0.3s;
}
#rating-stats > div {
  overflow: hidden;
}
#rating-stats.show {
  height: 130px;
}
#rating-stats .count {
  display: inline-block;
  padding: 1px 0 0 7px;
}
#rating-stats .count:before {
  content: "(";
}
#rating-stats .count:after {
  content: ")";
}

.reviewitem + .reviewitem {
  margin-top: 25px !important;
}

html {
  --reviewitem-balloon-background: #f7fbfe;
}

.reviewitem__balloon {
  background: var(--reviewitem-balloon-background);
  padding: 10px 10px 0;
  font-style: italic;
  overflow: hidden;
  filter: drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.04));
}

.reviewitem__balloonarrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--reviewitem-balloon-background);
  filter: drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.07));
  margin: 0 0 0 30px;
}

.reviewitem__author {
  font-weight: 700;
}

.reviewitem__starcontainer {
  display: inline-block;
  position: relative;
  height: 19px;
}

.pagination__page > a {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination__page:first-child > a,
.pagination__page:first-child > span {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 0;
}

.pagination__page > a {
  color: #00aeef;
}

.pagination__page > a:focus,
.pagination__page > a:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

.pagination__page--current > a,
.pagination__page--current > a:focus,
.pagination__page--current > a:hover {
  background-color: #00aeef;
  border-color: #00aeef;
}

.pagination__page--current > a,
.pagination__page--current > a:focus,
.pagination__page--current > a:hover {
  z-index: 3;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: default;
}

#rating-tooltip .rating-stars {
  position: relative;
  display: inline-block;
  width: 107px;
  height: 19px;
}

li.webshop-pagenav {
  list-style: none;
  display: inline-block;
  border: 1px solid #ddd;
  padding: 5px 10px;
  text-align: center;
}

li.webshop-pagenav + li.webshop-pagenav {
  border-left: none;
}

li.webshop-pagenav--current {
  background: #337ab7;
  color: #fff;
}

li.webshop-pagenav:not(.webshop-pagenav--current):hover {
  background: #ddd;
}

li.webshop-pagenav:not(.webshop-pagenav--current):hover a {
  text-decoration: none;
}

li.webshop-pagenav:first-child:not(.webshop-pagenav--first) .webshop-pagebuton__pagenr {
  display: none;
}
li.webshop-pagenav:first-child:not(.webshop-pagenav--first):before {
  content: "...";
}

li.webshop-pagenav:last-child:not(.webshop-pagenav--last) .webshop-pagebuton__pagenr {
  display: none;
}
li.webshop-pagenav:last-child:not(.webshop-pagenav--last):before {
  content: "...";
}