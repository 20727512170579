html.boxspring-config .hideon-kt-configurator,
.boxspring-config--hidden {
  display: none;
}

html.page--boxspringtool .contentbox {
  padding: 100px 0;
}
@media (max-width: 479px) {
  html.page--boxspringtool .contentbox {
    padding: 30px 0;
  }
}
html.page--boxspringtool .product__images__column .product__images {
  position: sticky;
  top: 90px;
}
@media (max-width: 992px) {
  html.page--boxspringtool .product__images__column {
    padding: 0;
  }
}
html.page--boxspringtool .webshop-product__option .col-3 label {
  margin-bottom: 0px !important;
}
html.page--boxspringtool .boxspring-config__matrastype.active,
html.page--boxspringtool .boxspring-config__toppertype.active {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container a u,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container a u {
  display: inline;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container a:not(.collapsed) > span,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container a:not(.collapsed) > span {
  display: none;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container a.collapsed > u,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container a.collapsed > u {
  display: none;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .col-3,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .col-3 {
  display: none;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .col-9,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .col-9 {
  flex: 0 0 100%;
  max-width: 100%;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras {
  margin-bottom: 5px;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-select,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-select {
  cursor: pointer;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-select .dd-selected,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-select .dd-selected {
  cursor: pointer;
  border: 2px solid #75b325;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-select .dd-selected.removeborder,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-select .dd-selected.removeborder {
  border: 0;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-select .dd-selected .dd-selected-image,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-select .dd-selected .dd-selected-image {
  max-width: 70px;
  width: 100%;
  margin-right: 0;
  background-color: #fff;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-select .dd-selected .dd-selected-text,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-select .dd-selected .dd-selected-text {
  cursor: pointer;
  float: left;
  margin: 10px 0 5px 5px;
  width: 78%;
  font-weight: 600;
  font-size: 16px;
}
@media (max-width: 420px) {
  html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-select .dd-selected .dd-selected-text,
  html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-select .dd-selected .dd-selected-text {
    margin: 5px 0 5px 5px;
    width: 60%;
  }
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-select .dd-selected .dd-desc,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-select .dd-selected .dd-desc {
  cursor: pointer;
  color: #807d79;
  float: none;
  width: 78%;
  font-weight: normal;
  display: inline-block;
  margin-left: 5px;
}
@media (max-width: 420px) {
  html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-select .dd-selected .dd-desc,
  html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-select .dd-selected .dd-desc {
    width: 60%;
  }
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-options,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-options {
  position: inherit;
  padding: 10px 0 5px 11px;
  background-color: #fff;
  display: block;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-options li,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-options li { /*
              &:first-child {
                display: none;
              }
*/ }
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-options li .dd-option,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-options li .dd-option {
  display: block;
  padding: 0;
  border: 2px solid transparent;
  margin: 0 8px 8px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-options li .dd-option.dd-option-selected,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-options li .dd-option.dd-option-selected {
  background-color: #fff;
  border: 2px solid #75b325;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-options li .dd-option .dd-option-image,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-options li .dd-option .dd-option-image {
  max-width: 70px;
  width: 100%;
  margin-right: 0;
  background-color: #fff;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-options li .dd-option .dd-option-text,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-options li .dd-option .dd-option-text {
  float: none;
  font-size: 16px;
  margin-left: 5px;
  width: 78%;
}
@media (max-width: 420px) {
  html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-options li .dd-option .dd-option-text,
  html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-options li .dd-option .dd-option-text {
    margin: 5px 0 5px 5px;
    width: 60%;
  }
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-options li .dd-option .dd-desc,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-options li .dd-option .dd-desc {
  color: #807d79;
  float: none;
  width: 78%;
  display: inline-block;
}
@media (max-width: 420px) {
  html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__matras .dd-container .dd-options li .dd-option .dd-desc,
  html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__matras .dd-container .dd-options li .dd-option .dd-desc {
    width: 60%;
  }
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper {
  margin-bottom: 5px;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-select,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-select {
  cursor: pointer;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-select .dd-selected,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-select .dd-selected {
  cursor: pointer;
  border: 2px solid #75b325;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-select .dd-selected.removeborder,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-select .dd-selected.removeborder {
  border: 0;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-select .dd-selected .dd-selected-image,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-select .dd-selected .dd-selected-image {
  max-width: 70px;
  width: 100%;
  margin-right: 0;
  background-color: #fff;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-select .dd-selected .dd-selected-text,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-select .dd-selected .dd-selected-text {
  cursor: pointer;
  float: left;
  margin: 10px 0 5px 5px;
  width: 78%;
  font-weight: 600;
  font-size: 16px;
}
@media (max-width: 420px) {
  html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-select .dd-selected .dd-selected-text,
  html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-select .dd-selected .dd-selected-text {
    margin: 5px 0 5px 5px;
    width: 60%;
  }
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-select .dd-selected .dd-desc,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-select .dd-selected .dd-desc {
  cursor: pointer;
  color: #807d79;
  float: none;
  width: 78%;
  font-weight: normal;
  display: inline-block;
  margin-left: 5px;
}
@media (max-width: 420px) {
  html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-select .dd-selected .dd-desc,
  html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-select .dd-selected .dd-desc {
    width: 60%;
  }
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-options,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-options {
  position: inherit;
  padding: 10px 0 5px 11px;
  background-color: #fff;
  display: block;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-options li,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-options li { /*
              &:first-child {
                display: none;
              }
*/ }
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-options li .dd-option,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-options li .dd-option {
  display: block;
  padding: 0;
  border: 2px solid transparent;
  margin: 0 8px 8px 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-options li .dd-option.dd-option-selected,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-options li .dd-option.dd-option-selected {
  background-color: #fff;
  border: 2px solid #75b325;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-options li .dd-option .dd-option-image,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-options li .dd-option .dd-option-image {
  float: left;
  max-width: 70px;
  width: 100%;
  margin-right: 0;
  background-color: #fff;
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-options li .dd-option .dd-option-text,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-options li .dd-option .dd-option-text {
  float: left;
  margin: 10px 0 5px 5px;
  width: 78%;
  font-weight: 600;
  font-size: 16px;
}
@media (max-width: 420px) {
  html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-options li .dd-option .dd-option-text,
  html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-options li .dd-option .dd-option-text {
    margin: 5px 0 5px 5px;
    width: 60%;
  }
}
html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-options li .dd-option .dd-desc,
html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-options li .dd-option .dd-desc {
  color: #807d79;
  float: none;
  width: 78%;
  font-weight: normal;
  display: inline-block;
  margin-left: 5px;
}
@media (max-width: 420px) {
  html.page--boxspringtool .boxspring-config__matrastype .product__options__container .option__topper .dd-container .dd-options li .dd-option .dd-desc,
  html.page--boxspringtool .boxspring-config__toppertype .product__options__container .option__topper .dd-container .dd-options li .dd-option .dd-desc {
    width: 60%;
  }
}
html.page--boxspringtool .boxspring-config__complete.active {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
html.page--boxspringtool .boxspring-config__optionlist ul li {
  font-size: 14px;
}
html.page--boxspringtool .boxspring-config__optionlist ul li strong {
  display: inline-block;
  width: 135px;
}
html.page--boxspringtool .col-collapse .collapse {
  display: inherit;
}
html.page--boxspringtool .row--cols > div {
  margin-bottom: 0;
}
html.page--boxspringtool .col-collapse .collapse {
  display: none;
}
html.page--boxspringtool .col-collapse .collapse.show {
  display: block;
}
html.page--boxspringtool .col-collapse .title:hover {
  cursor: pointer;
}
html.page--boxspringtool .col-collapse .title .fa-angle-up,
html.page--boxspringtool .col-collapse .title[aria-expanded=true] .fa-angle-down {
  display: none;
}
html.page--boxspringtool .col-collapse .title {
  margin-bottom: 15px;
  background-color: #fff;
  padding: 2px 10px;
  font-size: 15px;
  border: 1px solid #ccc;
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
}
html.page--boxspringtool .col-collapse .title[aria-expanded=true] .fa-angle-up {
  display: block;
}
html.page--boxspringtool .col-collapse .navbar-toggler {
  display: inline-block;
  padding: 0;
}
@media (max-width: 992px) {
  html.page--boxspringtool .product__images__grid {
    display: none;
  }
  html.page--boxspringtool .product__options__column.stapeen {
    padding: 20px;
    margin: -6px 0 70px 0;
    width: 100%;
  }
}