@charset "UTF-8";
html.combinepage .addedproduct {
  display: flex;
  width: 100%;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  position: relative;
}
html.combinepage .addedproduct__imageholder {
  flex: 0 0 120px;
  align-self: center;
  padding: 10px;
}
html.combinepage .addedproduct__imageholder > img {
  width: 100%;
  height: auto;
}
html.combinepage .addedproduct__titleholder {
  flex: 1 1 auto;
  padding: 10px 0;
  align-self: center;
}
html.combinepage .addedproduct__priceholder {
  padding: 15px 10px;
  flex: 180px 0 auto;
  min-width: 180px;
  white-space: nowrap;
}
html.combinepage .addedproduct__priceholder .addedproduct__price {
  display: block;
  font-size: 30px;
  line-height: 140%;
  padding-bottom: 10px;
}
html.combinepage .addedproduct__priceholder .addedproduct__price:before {
  content: "€";
  padding-right: 10px;
  display: inline-block;
}
@media (max-width: 530px) {
  html.combinepage .addedproduct {
    display: block;
    min-height: 110px;
    padding-right: 180px;
  }
  html.combinepage .addedproduct > * {
    flex: none;
  }
  html.combinepage .addedproduct__imageholder {
    width: 120px;
  }
  html.combinepage .addedproduct__titleholder {
    padding: 0 10px 10px;
  }
  html.combinepage .addedproduct__priceholder {
    position: absolute;
    right: 0;
    top: 0;
  }
}
html.combinepage .webshop-product__options {
  margin-top: 10px;
}
html.combinepage .webshop-product__optionlabel {
  font-weight: bold;
}
html.combinepage .ordercombineditem .webshop-product__add {
  margin-top: 15px;
  display: block;
}

.combinesection--hidden {
  display: none;
}
.combinesection .catname {
  text-transform: lowercase;
}
.combinesection__products {
  margin-bottom: 20px;
}

.combineheader {
  background-color: #fff;
  margin: 20px 0;
  padding: 20px;
  color: #4aa814;
  display: flex;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}
.combineheader h2 {
  color: inherit;
  font-weight: 700;
}
.combineheader p {
  font-size: 14px;
}
.combineheader p .arrow {
  width: 41px;
  height: 50px;
  margin: 5px 0 0 10px;
  vertical-align: top;
}
.combineheader > div {
  flex: 1 1 auto;
}
.combineheader > div.image {
  padding-left: 20px;
  flex: 0 0 320px;
}
@media (max-width: 767px) {
  .combineheader > div.image {
    display: none;
  }
}

.combinefooter {
  background-color: #fff;
  padding: 20px 10px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.ordercombineditem {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  height: 100%;
  text-decoration: none;
  justify-content: space-between;
  font: inherit;
  color: #584a45;
  transition: all 0.2s ease-in-out 0s;
  padding: 20px;
  border: 1px solid #bbb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: opacity 1s;
}
.ordercombineditem--hidden {
  display: none;
}
.ordercombineditem__pullright {
  order: 2;
  padding-left: 20px;
}
.ordercombineditem__pullright img {
  max-width: 100%;
}
@media (max-width: 479px) {
  .ordercombineditem {
    display: block;
  }
  .ordercombineditem--hidden {
    display: none;
  }
  .ordercombineditem__pullright {
    padding-left: 0;
    padding-bottom: 15px;
  }
}
.ordercombineditem__close {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #716059;
  width: 26px;
  height: 26px;
  color: #fff;
  padding: 3px;
  font-size: 18px;
  cursor: pointer;
}
.ordercombineditem__close:before {
  position: relative;
  font-family: "Font Awesome 5 Pro";
  content: "\f00d"; /* fa-times */
  left: 3px;
}
.ordercombineditem__end {
  clear: both;
}