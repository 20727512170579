#kt-wrapper {
  position: relative;
}

#kt-sub {
  overflow: hidden;
}

#kast-afbeeldingen,
#kt-sub .closet__config,
#kt-sub .manual__closet__config {
  border: 0px dotted #716059;
  background: #f7fbfe;
  box-shadow: inset 0 0 4px -1px #333;
  min-height: 380px;
  position: relative;
  margin-bottom: 20px;
}

#kt-sub .manual__closet__config .popover__wrapper {
  position: relative;
  display: inline-block;
}
#kt-sub .manual__closet__config .popover__wrapper .popover__content {
  opacity: 0;
  display: none;
  position: absolute;
  left: -210px;
  width: auto;
  bottom: calc(0px - 50%);
  padding: 1px;
  background-color: #bfbfbf;
}
#kt-sub .manual__closet__config .popover__wrapper .popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: -8px;
  bottom: 20px;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #bfbfbf;
}
#kt-sub .manual__closet__config .popover__wrapper .popover__content img {
  width: 200px;
  height: auto;
}
#kt-sub .manual__closet__config .popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  display: block;
}

#kast-bestellen {
  margin-left: -3px;
}

#kast-bestellen .dd-select {
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

#kt-sub .closet__config,
#kt-sub .manual__closet__config {
  position: absolute;
  top: 0;
  z-index: 1;
}

#kt-sub .manual__closet__config {
  box-shadow: none;
  min-height: 375px;
  top: 0px;
  left: 0px;
  width: 730px;
  box-shadow: inset 0 0 4px -1px #333;
}

#kt-sub .closet__config.show,
#kt-sub .manual__closet__config.show,
#kt-luxurypanels .luxury__article__panel.show {
  transition-property: visibility, opacity;
  transition-duration: 0s, 0.5s;
}

#kt-sub .manual__closet__config input[type=number] {
  width: 50px;
}

#kt-sub .closet__config.hide,
#kt-sub .manual__closet__config.hide,
#kt-luxurypanels .luxury__article__panel.hide {
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 0.5s, 0s;
  transition-delay: 0s, 0.5s;
}

#kt-luxurylist .dd-container,
#kt-luxurylist .dd-select,
#kt-luxurylist .dd-options {
  width: 100% !important;
}

#kast-afbeeldingen {
  padding: 17px;
  z-index: 0;
}

#kast-indeling #kast-afbeeldingen input[type=radio],
#kt-sub .closet__config input[type=radio] {
  display: none;
}

.w90-h1,
.w100-h1,
.hm90-h1,
.hm100-h1,
.a90-h1,
.a100-h1,
.b90-h1,
.b100-h1,
.c90-h1,
.c100-h1,
.d90-h1,
.d100-h1,
.el90-h1,
.el100-h1,
.er90-h1,
.er100-h1,
.f90-h1,
.f100-h1,
.fl90-h1,
.fl100-h1,
.fr90-h1,
.fr100-h1,
.g90-h1,
.g100-h1,
.h90-h1,
.h100-h1,
.hl90-h1,
.hl100-h1,
.hr90-h1,
.hr100-h1,
.i90-h1,
.i100-h1,
.j90-h1,
.j100-h1,
.s90-h1,
.s100-h1 {
  background-repeat: no-repeat;
  background-color: transparent;
}

.w45-h1,
.w50-h1,
.hm45-h1,
.hm50-h1,
.a45-h1,
.a50-h1,
.b45-h1,
.b50-h1,
.c45-h1,
.c50-h1,
.d45-h1,
.d50-h1,
.g45-h1,
.g50-h1,
.h45-h1,
.h50-h1,
.i45-h1,
.i50-h1,
.j45-h1,
.j50-h1,
.s45-h1,
.s50-h1,
.x45-h1,
.x50-h1 {
  background-repeat: no-repeat;
  background-color: transparent;
}

.w45-h2,
.w50-h2,
.hm45-h2,
.hm50-h2,
.a45-h2,
.a50-h2,
.b45-h2,
.b50-h2,
.c45-h2,
.c50-h2,
.d45-h2,
.d50-h2,
.f45-h2,
.f50-h2,
.g45-h2,
.g50-h2,
.h45-h2,
.h50-h2,
.i45-h2,
.i50-h2,
.j45-h2,
.j50-h2,
.s45-h2,
.s50-h2,
.x45-h2,
.x50-h2 {
  background-repeat: no-repeat;
  background-color: transparent;
}

.w50-j2,
.hm50-j2,
.a50-j2,
.b50-j2,
.c50-j2,
.d50-j2,
.g50-j2,
.h50-j2,
.j50-j2,
.m50-j2,
.p50-j2,
.s50-j2,
.x50-j2 {
  background-repeat: no-repeat;
  background-color: transparent;
}

.w60-h2,
.hm60-h2,
.a60-h2,
.b60-h2,
.c60-h2,
.d60-h2,
.g60-h2,
.h60-h2,
.i60-h2,
.j60-h2,
.s60-h2,
.x60-h2 {
  background-repeat: no-repeat;
  background-color: transparent;
}

.w66-h2,
.hm66-h2,
.a66-h2,
.b66-h2,
.c66-h2,
.d66-h2,
.dl66-h2,
.dr66-h2,
.el66-h2,
.er66-h2,
.fl66-h2,
.fr66-h2,
.s66-h2,
.xl66-h2,
.xr66-h2 {
  background-repeat: no-repeat;
  background-color: transparent;
}

.w75-h2,
.hm75-h2,
.a75-h2,
.b75-h2,
.c75-h2,
.d75-h2,
.el75-h2,
.er75-h2,
.fl75-h2,
.fr75-h2,
.g75-h2,
.gl75-h2,
.gr75-h2,
.h75-h2,
.hl75-h2,
.hr75-h2,
.i75-h2,
.j75-h2,
.jl75-h2,
.jr75-h2,
.s75-h2,
.x75-h2,
.xl75-h2,
.xr75-h2 {
  background-repeat: no-repeat;
  background-color: transparent;
}

.w80-h2,
.hm80-h2,
.a80-h2,
.b80-h2,
.c80-h2,
.d80-h2,
.el80-h2,
.er80-h2,
.fl80-h2,
.fr80-h2,
.g80-h2,
.gl80-h2,
.gr80-h2,
.h80-h2,
.hl80-h2,
.hr80-h2,
.i80-h2,
.j80-h2,
.jl80-h2,
.jr80-h2,
.ll80-h2,
.lr80-h2,
.nl80-h2,
.nr80-h2,
.s80-h2,
.tl80-h2,
.tr80-h2,
.x80-h2,
.xl80-h2,
.xr80-h2 {
  background-repeat: no-repeat;
  background-color: transparent;
}

.w90-h2,
.w100-h2,
.hm90-h2,
.hm100-h2,
.a90-h2,
.a100-h2,
.b90-h2,
.b100-h2,
.c90-h2,
.c100-h2,
.d90-h2,
.d100-h2,
.dl90-h2,
.dl100-h2,
.dr90-h2,
.dr100-h2,
.el90-h2,
.el100-h2,
.er90-h2,
.er100-h2,
.fl90-h2,
.fl100-h2,
.fr90-h2,
.fr100-h2,
.f90-h2,
.f100-h2,
.g90-h2,
.g100-h2,
.gl90-h2,
.gl100-h2,
.gr90-h2,
.gr100-h2,
.h90-h2,
.h100-h2,
.hl90-h2,
.hl200-h2,
.hr90-h2,
.hr100-h2,
.i90-h2,
.i100-h2,
.j90-h2,
.j100-h2,
.jl90-h2,
.jl100-h2,
.jr90-h2,
.jr100-h2,
.kl90-h2,
.kl100-h2,
.kr90-h2,
.kr100-h2,
.ll90-h2,
.ll100-h2,
.lr90-h2,
.lr100-h2,
.nl90-h2,
.nl100-h2,
.nr90-h2,
.nr100-h2,
.n90-h2,
.n100-h2,
.s90-h2,
.s100-h2,
.tl90-h2,
.tl100-h2,
.tr90-h2,
.tr100-h2,
.x90-h2,
.x100-h2,
.xl90-h2,
.xl100-h2,
.xr90-h2,
.xr100-h2 {
  background-repeat: no-repeat;
  background-color: transparent;
}

.w100-j2,
.hm100-j2,
.d100-j2,
.pl100-j2,
.pr100-j2,
.ml100-j2,
.mr100-j2,
.s100-j2,
.b100-j2,
.c100-j2,
.a100-j2,
.g100-j2,
.j100-j2,
.el100-j2,
.er100-j2,
.fr100-j2,
.fl100-j2 {
  background-repeat: no-repeat;
  background-color: transparent;
}

.w120-h2,
.hm120-h2,
.a120-h2,
.b120-h2,
.c120-h2,
.d120-h2,
.el120-h2,
.er120-h2,
.g120-h2,
.i120-h2,
.j120-h2,
.fr120-h2,
.fl120-h2,
.ll120-h2,
.lr120-h2,
.nl120-h2,
.nr120-h2,
.s120-h2,
.tr120-h2,
.tl120-h2,
.x120-h2 {
  background-repeat: no-repeat;
  background-color: transparent;
}

#kast-afbeeldingen .w45-h2 > .chosen__closet__config,
#kast-afbeeldingen .w50-h2 > .chosen__closet__config,
#kast-afbeeldingen .w50-j2 > .chosen__closet__config,
#kast-afbeeldingen .w45-h2 > .choose__closet__config,
#kast-afbeeldingen .w50-h2 > .choose__closet__config,
#kast-afbeeldingen .w50-j2 > .choose__closet__config {
  width: 54px;
  height: 280px;
  float: left;
}

#kast-afbeeldingen .w45-h1 > .chosen__closet__config,
#kast-afbeeldingen .w50-h1 > .chosen__closet__config,
#kast-afbeeldingen .w45-h1 > .choose__closet__config,
#kast-afbeeldingen .w50-h1 > .choose__closet__config {
  width: 54px;
  height: 265px;
  float: left;
}

#kast-afbeeldingen .w60-h2 > .chosen__closet__config,
#kast-afbeeldingen .w60-h2 > .choose__closet__config {
  width: 65px;
  height: 265px;
  float: left;
}

#kast-afbeeldingen .w66-h2 > .chosen__closet__config,
#kast-afbeeldingen .w66-h2 > .choose__closet__config {
  width: 77px;
  height: 265px;
  float: left;
}

#kast-afbeeldingen .w75-h2 > .chosen__closet__config,
#kast-afbeeldingen .w75-h2 > .choose__closet__config {
  width: 84px;
  height: 280px;
  float: left;
}

#kast-afbeeldingen .productline_wiemanna.w75-h2 > .chosen__closet__config,
#kast-afbeeldingen .productline_wiemanna.w75-h2 > .choose__closet__config,
#kast-afbeeldingen .productline_wiemanna.w80-h2 > .chosen__closet__config,
#kast-afbeeldingen .productline_wiemanna.w80-h2 > .choose__closet__config {
  width: 81px;
  height: 268px;
  float: left;
}

#kast-afbeeldingen .w75-h2 > .chosen__closet__config,
#kast-afbeeldingen .w75-h2 > .choose__closet__config,
#kast-afbeeldingen .w80-h2 > .chosen__closet__config,
#kast-afbeeldingen .w80-h2 > .choose__closet__config {
  width: 86px;
  height: 265px;
  float: left;
}

#kast-afbeeldingen .w90-h2 > .chosen__closet__config,
#kast-afbeeldingen .w100-h2 > .chosen__closet__config,
#kast-afbeeldingen .w100-j2 > .chosen__closet__config,
#kast-afbeeldingen .w90-h2 > .choose__closet__config,
#kast-afbeeldingen .w100-h2 > .choose__closet__config,
#kast-afbeeldingen .w100-j2 > .choose__closet__config {
  width: 107px;
  height: 280px;
  float: left;
}

#kast-afbeeldingen .w90-h1 > .chosen__closet__config,
#kast-afbeeldingen .w100-h1 > .chosen__closet__config,
#kast-afbeeldingen .w90-h1 > .choose__closet__config,
#kast-afbeeldingen .w100-h1 > .choose__closet__config {
  width: 107px;
  height: 257px;
  float: left;
}

#kast-afbeeldingen .w120-h2 > .chosen__closet__config,
#kast-afbeeldingen .w120-h2 > .choose__closet__config {
  width: 127px;
  height: 270px;
  float: left;
}

.w45-h1,
.w50-h1,
.w45-h2,
.w50-h2,
.w50-j2 {
  padding: 4px 0 0 0;
  float: left;
}

.w60-h2 {
  padding: 5px 0 0 0;
  float: left;
}

.w66-h2 {
  padding: 6px 0 0 0;
  float: left;
}

.w75-h2 {
  padding: 7px 0 0 0;
  float: left;
}

.w80-h2 {
  padding: 7px 0 0 0;
  float: left;
}

.w90-h1,
.w100-h1,
.w90-h2,
.w100-h2,
.w100-j2 {
  padding: 9px 0 0 0;
  float: left;
}

.w120-h2 {
  padding: 10px 0 0 0;
  float: left;
}

.w45-h2 > .chosen__closet__config,
.w50-h2 > .chosen__closet__config,
.w50-j2 > .chosen__closet__config,
.w45-h2 > .choose__closet__config,
.w50-h2 > .choose__closet__config,
.w50-j2 > .choose__closet__config {
  width: 110px;
  height: 280px;
  float: left;
}

.w45-h1 > .chosen__closet__config,
.w50-h1 > .chosen__closet__config,
.w45-h1 > .choose__closet__config,
.w50-h1 > .choose__closet__config {
  width: 110px;
  height: 257px;
  float: left;
}

.w90-h2 > .chosen__closet__config,
.w100-h2 > .chosen__closet__config,
.w100-j2 > .chosen__closet__config,
.w90-h2 > .choose__closet__config,
.w100-h2 > .choose__closet__config,
.w100-j2 > .choose__closet__config {
  width: 164px;
  height: 280px;
  float: left;
}

.w90-h1 > .chosen__closet__config,
.w100-h1 > .chosen__closet__config,
.w90-h1 > .choose__closet__config,
.w100-h1 > .choose__closet__config {
  width: 164px;
  height: 257px;
  float: left;
}

.w60-h2 label.chosen__closet__config,
.w60-h2 label.choose__closet__config {
  width: 118px;
  height: 273px;
  float: left;
}

.w66-h2 label.chosen__closet__config,
.w66-h2 label.choose__closet__config {
  width: 129px;
  height: 273px;
  float: left;
}

.w75-h2 label.chosen__closet__config,
.w75-h2 label.choose__closet__config {
  width: 137px;
  height: 285px;
  float: left;
}

.w80-h2 label.chosen__closet__config,
.w80-h2 label.choose__closet__config {
  width: 135px;
  height: 280px;
  float: left;
}

.w90-h2 label.chosen__closet__config,
.w100-h2 label.chosen__closet__config,
.w90-h2 label.choose__closet__config,
.w100-h2 label.choose__closet__config {
  width: 161px;
  height: 269px;
  float: left;
}

.w120-h2 label.chosen__closet__config,
.w120-h2 label.choose__closet__config {
  width: 180px;
  height: 272px;
  float: left;
}

.w90-h1 label.chosen__closet__config,
.w100-h1 label.chosen__closet__config,
.w90-h1 label.choose__closet__config,
.w100-h1 label.choose__closet__config {
  width: 161px;
  height: 257px;
  float: left;
}

.w45-h2 label.chosen__closet__config,
.w50-h2 label.chosen__closet__config,
.w45-h2 label.choose__closet__config .w50-h2 label.choose__closet__config {
  display: block;
  width: 105px;
  height: 285px;
}

.w45-h1 label.chosen__closet__config,
.w50-h1 label.chosen__closet__config,
.w45-h1 label.choose__closet__config,
.w50-h1 label.choose__closet__config {
  display: block;
  width: 105px;
  height: 257px;
}

#kt-sub label {
  background-image: none !important;
  cursor: pointer;
}

.kast__luxuryproducts {
  float: left;
}

.wijzig {
  font-family: Arial;
  font-size: 11px;
  font-weight: normal;
  position: absolute;
  width: 80px;
  padding: 6px 15px;
  left: 0;
  bottom: -40px;
  text-align: center;
  border-radius: 4px;
}

.wijzig .tip {
  position: absolute;
  left: 50%;
  height: 0;
  width: 0;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: -10px;
  margin-left: -5px;
}

.chosen__closet__config:hover .wijzig .tip {
  border-bottom-color: #716059;
}

.chosen__closet__config:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.chosen__closet__config:hover .wijzig {
  background: #716059;
  color: #fff;
}

.kast-container {
  position: relative;
}

.filler {
  width: 45px;
  float: left;
}

#kast-indeling .kast__wrapper,
#kt-sub .ToolBase .kast__wrapper {
  border: 2px solid transparent;
  background-position: 10px 10px;
  margin: 5px;
  border-radius: 3px;
  position: relative;
}

#kt-sub .ToolBase .kast__wrapper.selected,
#kt-sub .manual__config {
  border: 2px solid #1CA632;
}

#kt-sub .manual__config {
  float: left;
  display: block;
  padding: 10px;
  margin: 10px;
  background: none repeat scroll 0 0 #fff;
  border: 1px solid #D1D7D9;
  text-decoration: none;
  border-radius: 2px;
  font-size: 16px;
  font-weight: bold;
  color: #716059;
  display: none;
}

#kt-sub .manual__config span {
  text-decoration: none;
  font-size: 12px;
  font-weight: normal;
  line-height: 21px;
  color: #999;
}

#kt-sub .kast__luxuryproducts .aantal {
  width: 50px;
}

#kt-luxurypanels {
  position: relative;
}
#kt-luxurypanels .luxury__article__panel {
  width: 100%;
  min-height: 350px;
  position: absolute;
  z-index: 2000;
  left: 0;
  top: 0;
  background: #f7fbfe;
}
@media (max-width: 480px) {
  #kt-luxurypanels .luxury__article__panel {
    min-height: 235px;
  }
}
#kt-luxurypanels .luxury__article__panel label.disabled {
  opacity: 0.5;
}
#kt-luxurypanels img {
  float: right;
  max-width: 190px;
  max-height: 200px;
  margin-left: 10px;
  border: 1px solid #716059;
}
@media (max-width: 480px) {
  #kt-luxurypanels img {
    max-width: 100px;
  }
}
#kt-luxurypanels .form-check {
  padding-left: 0;
  margin-bottom: 5px;
}
#kt-luxurypanels .form-check input {
  margin-right: 5px;
}
#kt-luxurypanels .form-check label {
  font-size: 13px;
}
#kt-luxurypanels p:empty {
  display: none;
}
#kt-luxurypanels .luxury__article__close {
  position: absolute;
  top: -15px;
  right: -5px;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}

ul.luxury__articles {
  display: block;
  position: absolute;
  bottom: 20px;
  padding: 0;
  margin: 0 4px;
  list-style: none outside none;
  z-index: 1000;
}

ul.luxury__articles.w100,
ul.luxury__articles.w90 {
  width: 100px;
}

ul.luxury__articles.w50,
ul.luxury__articles.w45 {
  width: 50px;
}

ul.luxury__articles li {
  display: none;
  margin: 1px 0;
  padding: 2px;
  background: rgba(113, 96, 89, 0.1);
  text-align: center;
  border-radius: 2px;
  border: 1px dotted #716059;
  font-size: 11px;
  line-height: 14px;
}

@media (min-width: 980px) {
  .w90-h1,
  .w100-h1,
  .hm90-h1,
  .hm100-h1,
  .a90-h1,
  .a100-h1,
  .b90-h1,
  .b100-h1,
  .c90-h1,
  .c100-h1,
  .d90-h1,
  .d100-h1,
  .el90-h1,
  .el100-h1,
  .er90-h1,
  .er100-h1,
  .f90-h1,
  .f100-h1,
  .fl90-h1,
  .fl100-h1,
  .fr90-h1,
  .fr100-h1,
  .g90-h1,
  .g100-h1,
  .h90-h1,
  .h100-h1,
  .hl90-h1,
  .hl100-h1,
  .hr90-h1,
  .hr100-h1,
  .i90-h1,
  .i100-h1,
  .j90-h1,
  .j100-h1,
  .s90-h1,
  .s100-h1 {
    background-size: 140px !important;
  }
  .w45-h1,
  .w50-h1,
  .hm45-h1,
  .hm50-h1,
  .a45-h1,
  .a50-h1,
  .b45-h1,
  .b50-h1,
  .c45-h1,
  .c50-h1,
  .d45-h1,
  .d50-h1,
  .g45-h1,
  .g50-h1,
  .h45-h1,
  .h50-h1,
  .i45-h1,
  .i50-h1,
  .j45-h1,
  .j50-h1,
  .s45-h1,
  .s50-h1,
  .x45-h1,
  .x50-h1 {
    background-size: 87px !important;
  }
  .w90-h2,
  .w100-h2,
  .hm90-h2,
  .hm100-h2,
  .a90-h2,
  .a100-h2,
  .b90-h2,
  .b100-h2,
  .c90-h2,
  .c100-h2,
  .d90-h2,
  .d100-h2,
  .dl90-h2,
  .dl100-h2,
  .dr90-h2,
  .dr100-h2,
  .el90-h2,
  .el100-h2,
  .er90-h2,
  .er100-h2,
  .fl90-h2,
  .fl100-h2,
  .fr90-h2,
  .fr100-h2,
  .f90-h2,
  .f100-h2,
  .g90-h2,
  .g100-h2,
  .gl90-h2,
  .gl100-h2,
  .gr90-h2,
  .gr100-h2,
  .h90-h2,
  .h100-h2,
  .hl90-h2,
  .hl200-h2,
  .hr90-h2,
  .hr100-h2,
  .i90-h2,
  .i100-h2,
  .j90-h2,
  .j100-h2,
  .jl90-h2,
  .jl100-h2,
  .jr90-h2,
  .jr100-h2,
  .kl90-h2,
  .kl100-h2,
  .kr90-h2,
  .kr100-h2,
  .n90-h2,
  .n100-h2,
  .s90-h2,
  .s100-h2,
  .x90-h2,
  .x100-h2,
  .xl90-h2,
  .xl100-h2,
  .xr90-h2,
  .xr100-h2 {
    background-size: 140px !important;
  }
  .w120-h2,
  .hm120-h2,
  .a120-h2,
  .b120-h2,
  .c120-h2,
  .d120-h2,
  .el120-h2,
  .er120-h2,
  .g120-h2,
  .i120-h2,
  .j120-h2,
  .fr120-h2,
  .fl120-h2,
  .ll120-h2,
  .lr120-h2,
  .nl120-h2,
  .nr120-h2,
  .s120-h2,
  .tr120-h2,
  .tl120-h2,
  .x120-h2 {
    background-size: 160px !important;
  }
  .w45-h2,
  .w50-h2,
  .hm45-h2,
  .hm50-h2,
  .a45-h2,
  .a50-h2,
  .b45-h2,
  .b50-h2,
  .c45-h2,
  .c50-h2,
  .d45-h2,
  .d50-h2,
  .f45-h2,
  .f50-h2,
  .g45-h2,
  .g50-h2,
  .h45-h2,
  .h50-h2,
  .i45-h2,
  .i50-h2,
  .j45-h2,
  .j50-h2,
  .s45-h2,
  .s50-h2,
  .x45-h2,
  .x50-h2 {
    background-size: 87px !important;
  }
  .w60-h2,
  .hm60-h2,
  .a60-h2,
  .b60-h2,
  .c60-h2,
  .d60-h2,
  .g60-h2,
  .h60-h2,
  .i60-h2,
  .j60-h2,
  .s60-h2,
  .x60-h2 {
    background-size: 98px !important;
  }
  .w66-h2,
  .hm66-h2,
  .a66-h2,
  .b66-h2,
  .c66-h2,
  .d66-h2,
  .dl66-h2,
  .dr66-h2,
  .el66-h2,
  .er66-h2,
  .fl66-h2,
  .fr66-h2,
  .s66-h2,
  .xl66-h2,
  .xr66-h2 {
    background-size: 109px !important;
  }
  .w75-h2,
  .hm75-h2,
  .a75-h2,
  .b75-h2,
  .c75-h2,
  .d75-h2,
  .el75-h2,
  .er75-h2,
  .fl75-h2,
  .fr75-h2,
  .g75-h2,
  .gl75-h2,
  .gr75-h2,
  .h75-h2,
  .hl75-h2,
  .hr75-h2,
  .i75-h2,
  .j75-h2,
  .jl75-h2,
  .jr75-h2,
  .s75-h2,
  .x75-h2,
  .xl75-h2,
  .xr75-h2 {
    background-size: 118px !important;
  }
  .w80-h2,
  .hm80-h2,
  .a80-h2,
  .b80-h2,
  .c80-h2,
  .d80-h2,
  .el80-h2,
  .er80-h2,
  .d80-h2,
  .g80-h2,
  .h80-h2,
  .i80-h2,
  .j80-h2,
  .ll80-h2,
  .lr80-h2,
  .nl80-h2,
  .nr80-h2,
  .s80-h2,
  .tl80-h2,
  .tr80-h2,
  .x80-h2 {
    background-size: 118px !important;
  }
  .productline_wiemanna.w75-h2,
  .productline_wiemanna.w80-h2,
  .productline_wiemanna.hm75-h2,
  .productline_wiemanna.hm80-h2,
  .productline_wiemanna.a75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.b75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.c75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.d75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.el75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.er75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.d75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.g75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.h75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.i75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.j75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.ll75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.lr75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.nl75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.nr75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.s75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.tl75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.tr75-h2,
  .productline_wiemanna.a80-h2,
  .productline_wiemanna.x75-h2,
  .productline_wiemanna.x80-h2 {
    background-size: 113px !important;
  }
  .w50-j2,
  .hm50-j2,
  .j50-j2,
  .a50-j2,
  .g50-j2,
  .s50-j2,
  .h50-j2,
  .b50-j2,
  .p50-j2,
  .x50-j2,
  .d50-j2,
  .c50-j2,
  .m50-j2 {
    background-size: 87px !important;
  }
  .w100-j2,
  .hm100-j2,
  .d100-j2,
  .pl100-j2,
  .pr100-j2,
  .ml100-j2,
  .mr100-j2,
  .s100-j2,
  .b100-j2,
  .c100-j2,
  .a100-j2,
  .g100-j2,
  .j100-j2,
  .el100-j2,
  .er100-j2,
  .fr100-j2,
  .fl100-j2 {
    background-size: 140px !important;
  }
}
@media (min-width: 480px) and (max-width: 979px) {
  #kast-bestellen {
    margin-left: 0;
    margin-bottom: 20px;
  }
  #kast-afbeeldingen,
  #kt-sub .closet__config,
  #kt-sub .manual__closet__config {
    min-height: 350px;
  }
  #kast-indeling .kast__wrapper,
  #kt-sub .ToolBase .kast__wrapper {
    margin: 1px;
  }
  #kt-sub .manual__closet__config {
    width: 100%;
  }
  .w90-h1,
  .w90-h2,
  .w100-h1,
  .w100-h2,
  .w100-j2 {
    padding: 7px 0 0;
  }
  .w66-h2 {
    padding: 5px 0 0 0;
  }
  .w75-h2 {
    padding: 6px 0 0 0;
  }
  .w80-h2 {
    padding: 6px 0 0 0;
  }
  .w120-h2 {
    padding: 9px 0 0 0;
  }
  .w90-h1,
  .w100-h1,
  .hm90-h1,
  .hm100-h1,
  .a90-h1,
  .a100-h1,
  .b90-h1,
  .b100-h1,
  .c90-h1,
  .c100-h1,
  .d90-h1,
  .d100-h1,
  .el90-h1,
  .el100-h1,
  .er90-h1,
  .er100-h1,
  .f90-h1,
  .f100-h1,
  .fl90-h1,
  .fl100-h1,
  .fr90-h1,
  .fr100-h1,
  .g90-h1,
  .g100-h1,
  .h90-h1,
  .h100-h1,
  .hl90-h1,
  .hl100-h1,
  .hr90-h1,
  .hr100-h1,
  .i90-h1,
  .i100-h1,
  .j90-h1,
  .j100-h1,
  .s90-h1,
  .s100-h1 {
    background-size: 117px !important;
  }
  .w45-h1,
  .w50-h1,
  .hm45-h1,
  .hm50-h1,
  .a45-h1,
  .a50-h1,
  .b45-h1,
  .b50-h1,
  .c45-h1,
  .c50-h1,
  .d45-h1,
  .d50-h1,
  .g45-h1,
  .g50-h1,
  .h45-h1,
  .h50-h1,
  .i45-h1,
  .i50-h1,
  .j45-h1,
  .j50-h1,
  .s45-h1,
  .s50-h1,
  .x45-h1,
  .x50-h1 {
    background-size: 73px !important;
  }
  .w90-h2,
  .w100-h2,
  .hm90-h2,
  .hm100-h2,
  .a90-h2,
  .a100-h2,
  .b90-h2,
  .b100-h2,
  .c90-h2,
  .c100-h2,
  .d90-h2,
  .d100-h2,
  .dl90-h2,
  .dl100-h2,
  .dr90-h2,
  .dr100-h2,
  .el90-h2,
  .el100-h2,
  .er90-h2,
  .er100-h2,
  .fl90-h2,
  .fl100-h2,
  .fr90-h2,
  .fr100-h2,
  .f90-h2,
  .f100-h2,
  .g90-h2,
  .g100-h2,
  .gl90-h2,
  .gl100-h2,
  .gr90-h2,
  .gr100-h2,
  .h90-h2,
  .h100-h2,
  .hl90-h2,
  .hl200-h2,
  .hr90-h2,
  .hr100-h2,
  .i90-h2,
  .i100-h2,
  .j90-h2,
  .j100-h2,
  .jl90-h2,
  .jl100-h2,
  .jr90-h2,
  .jr100-h2,
  .kl90-h2,
  .kl100-h2,
  .kr90-h2,
  .kr100-h2,
  .n90-h2,
  .n100-h2,
  .s90-h2,
  .s100-h2,
  .x90-h2,
  .x100-h2,
  .xl90-h2,
  .xl100-h2,
  .xr90-h2,
  .xr100-h2 {
    background-size: 117px !important;
  }
  .w45-h2,
  .w50-h2,
  .hm45-h2,
  .hm50-h2,
  .a45-h2,
  .a50-h2,
  .b45-h2,
  .b50-h2,
  .c45-h2,
  .c50-h2,
  .d45-h2,
  .d50-h2,
  .f45-h2,
  .f50-h2,
  .g45-h2,
  .g50-h2,
  .h45-h2,
  .h50-h2,
  .i45-h2,
  .i50-h2,
  .j45-h2,
  .j50-h2,
  .s45-h2,
  .s50-h2,
  .x45-h2,
  .x50-h2 {
    background-size: 73px !important;
  }
  .w120-h2,
  .hm120-h2,
  .a120-h2,
  .b120-h2,
  .c120-h2,
  .d120-h2,
  .el120-h2,
  .er120-h2,
  .g120-h2,
  .i120-h2,
  .j120-h2,
  .fr120-h2,
  .fl120-h2,
  .ll120-h2,
  .lr120-h2,
  .nl120-h2,
  .nr120-h2,
  .s120-h2,
  .tr120-h2,
  .tl120-h2,
  .x120-h2 {
    background-size: 134px !important;
  }
  .w45-h2,
  .w50-h2,
  .hm45-h2,
  .hm50-h2,
  .a45-h2,
  .a50-h2,
  .b45-h2,
  .b50-h2,
  .c45-h2,
  .c50-h2,
  .d45-h2,
  .d50-h2,
  .f45-h2,
  .f50-h2,
  .g45-h2,
  .g50-h2,
  .h45-h2,
  .h50-h2,
  .i45-h2,
  .i50-h2,
  .j45-h2,
  .j50-h2,
  .s45-h2,
  .s50-h2,
  .x45-h2,
  .x50-h2 {
    background-size: 73px !important;
  }
  .w60-h2,
  .hm60-h2,
  .a60-h2,
  .b60-h2,
  .c60-h2,
  .d60-h2,
  .g60-h2,
  .h60-h2,
  .i60-h2,
  .j60-h2,
  .s60-h2,
  .x60-h2 {
    background-size: 82px !important;
  }
  .w66-h2,
  .hm66-h2,
  .a66-h2,
  .b66-h2,
  .c66-h2,
  .d66-h2,
  .dl66-h2,
  .dr66-h2,
  .el66-h2,
  .er66-h2,
  .fl66-h2,
  .fr66-h2,
  .s66-h2,
  .xl66-h2,
  .xr66-h2 {
    background-size: 92px !important;
  }
  .w75-h2,
  .hm75-h2,
  .a75-h2,
  .b75-h2,
  .c75-h2,
  .d75-h2,
  .el75-h2,
  .er75-h2,
  .fl75-h2,
  .fr75-h2,
  .g75-h2,
  .gl75-h2,
  .gr75-h2,
  .h75-h2,
  .hl75-h2,
  .hr75-h2,
  .i75-h2,
  .j75-h2,
  .jl75-h2,
  .jr75-h2,
  .s75-h2,
  .x75-h2,
  .xl75-h2,
  .xr75-h2 {
    background-size: 98px !important;
  }
  .w80-h2,
  .hm80-h2,
  .a80-h2,
  .b80-h2,
  .c80-h2,
  .el80-h2,
  .er80-h2,
  .d80-h2,
  .g80-h2,
  .h80-h2,
  .i80-h2,
  .j80-h2,
  .ll80-h2,
  .lr80-h2,
  .nl80-h2,
  .nr80-h2,
  .s80-h2,
  .tl80-h2,
  .tr80-h2,
  .x80-h2 {
    background-size: 98px !important;
  }
  .w50-j2,
  .hm50-j2,
  .j50-j2,
  .a50-j2,
  .g50-j2,
  .s50-j2,
  .h50-j2,
  .b50-j2,
  .p50-j2,
  .x50-j2,
  .d50-j2,
  .c50-j2,
  .m50-j2 {
    background-size: 73px !important;
  }
  .w100-j2,
  .hm100-j2,
  .d100-j2,
  .pl100-j2,
  .pr100-j2,
  .ml100-j2,
  .mr100-j2,
  .s100-j2,
  .b100-j2,
  .c100-j2,
  .a100-j2,
  .g100-j2,
  .j100-j2,
  .el100-j2,
  .er100-j2,
  .fr100-j2,
  .fl100-j2 {
    background-size: 117px !important;
  }
  #kast-afbeeldingen .w45-h1 > .chosen__closet__config,
  #kast-afbeeldingen .w50-h1 > .chosen__closet__config,
  #kast-afbeeldingen .w45-h1 > .choose__closet__config,
  #kast-afbeeldingen .w50-h1 > .choose__closet__config {
    width: 46px;
    height: 200px;
  }
  #kast-afbeeldingen .w45-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w50-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w50-j2 > .chosen__closet__config,
  #kast-afbeeldingen .w45-h2 > .choose__closet__config,
  #kast-afbeeldingen .w50-h2 > .choose__closet__config,
  #kast-afbeeldingen .w50-j2 > .choose__closet__config {
    width: 48px;
    height: 220px;
  }
  #kast-afbeeldingen .w60-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w60-h2 > .choose__closet__config {
    width: 54px;
    height: 222px;
  }
  #kast-afbeeldingen .w66-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w66-h2 > .choose__closet__config {
    width: 65px;
    height: 222px;
  }
  #kast-afbeeldingen .w75-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w75-h2 > .choose__closet__config {
    width: 71px;
    height: 235px;
  }
  #kast-afbeeldingen .w80-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w80-h2 > .choose__closet__config {
    width: 71px;
    height: 225px;
  }
  #kast-afbeeldingen .w90-h1 > .chosen__closet__config,
  #kast-afbeeldingen .w100-h1 > .chosen__closet__config,
  #kast-afbeeldingen .w90-h1 > .choose__closet__config,
  #kast-afbeeldingen .w100-h1 > .choose__closet__config {
    width: 90px;
    height: 200px;
  }
  #kast-afbeeldingen .w90-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w100-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w100-j2 > .chosen__closet__config,
  #kast-afbeeldingen .w90-h2 > .choose__closet__config,
  #kast-afbeeldingen .w100-h2 > .choose__closet__config,
  #kast-afbeeldingen .w100-j2 > .choose__closet__config {
    width: 90px;
    height: 220px;
  }
  #kast-afbeeldingen .w120-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w120-h2 > .choose__closet__config {
    width: 107px;
    height: 225px;
  }
  .w45-h1 > .chosen__closet__config,
  .w50-h1 > .chosen__closet__config,
  .w45-h1 > .choose__closet__config,
  .w50-h1 > .choose__closet__config {
    width: 110px;
    height: 200px;
  }
  .w45-h2 > .chosen__closet__config,
  .w50-h2 > .chosen__closet__config,
  .w50-j2 > .chosen__closet__config,
  .w45-h2 > .choose__closet__config,
  .w50-h2 > .choose__closet__config,
  .w50-j2 > .choose__closet__config {
    width: 110px;
    height: 220px;
  }
  .w60-h2 > .chosen__closet__config,
  .w60-h2 > .choose__closet__config {
    width: 60px;
    height: 230px;
  }
  .w66-h2 > .chosen__closet__config,
  .w66-h2 > .choose__closet__config {
    width: 65px;
    height: 230px;
  }
  .w75-h2 > .chosen__closet__config,
  .w75-h2 > .choose__closet__config {
    width: 70px;
    height: 230px;
  }
  .w80-h2 > .chosen__closet__config,
  .w80-h2 > .choose__closet__config {
    width: 70px;
    height: 230px;
  }
  .w90-h2 > .chosen__closet__config,
  .w100-h2 > .chosen__closet__config,
  .w100-j2 > .chosen__closet__config,
  .w90-h2 > .choose__closet__config,
  .w100-h2 > .choose__closet__config,
  .w100-j2 > .choose__closet__config {
    width: 138px;
    height: 230px;
  }
  .w120-h2 > .chosen__closet__config,
  .w120-h2 > .choose__closet__config {
    width: 150px;
    height: 230px;
  }
  .w90-h1 > .chosen__closet__config,
  .w100-h1 > .chosen__closet__config,
  .w90-h1 > .choose__closet__config,
  .w100-h1 > .choose__closet__config {
    width: 164px;
    height: 210px;
  }
  .w45-h1 label.chosen__closet__config,
  .w50-h1 label.chosen__closet__config,
  .w45-h1 label.choose__closet__config,
  .w50-h1 label.choose__closet__config {
    width: 105px;
    height: 210px;
  }
  .w60-h2 > label.chosen__closet__config,
  .w60-h2 > label.choose__closet__config {
    width: 110px;
    height: 232px;
  }
  .w66-h2 > label.chosen__closet__config,
  .w66-h2 > label.choose__closet__config {
    width: 110px;
    height: 232px;
  }
  .w45-h2 label.chosen__closet__config,
  .w50-h2 label.chosen__closet__config,
  .w45-h2 label.choose__closet__config,
  .w50-h2 label.choose__closet__config {
    width: 94px;
    height: 232px;
  }
  .w75-h2 > label.chosen__closet__config,
  .w75-h2 > label.choose__closet__config {
    width: 120px;
    height: 240px;
  }
  .w80-h2 > label.chosen__closet__config,
  .w80-h2 > label.choose__closet__config {
    width: 120px;
    height: 240px;
  }
  .w90-h2 label.chosen__closet__config,
  .w100-h2 label.chosen__closet__config,
  .w90-h2 label.choose__closet__config,
  .w100-h2 label.choose__closet__config {
    width: 135px;
    height: 230px;
  }
  .w90-h1 label.chosen__closet__config,
  .w100-h1 label.chosen__closet__config,
  .w90-h1 label.choose__closet__config,
  .w100-h1 label.choose__closet__config {
    width: 135px;
    height: 210px;
  }
  .w120-h2 > label.chosen__closet__config,
  .w120-h2 > label.choose__closet__config {
    width: 150px;
    height: 240px;
  }
}
@media (max-width: 480px) {
  #kast-bestellen {
    margin-left: 0;
    margin-bottom: 20px;
  }
  #kast-afbeeldingen {
    padding: 10px;
  }
  #kt-sub .manual__closet__config {
    width: 100%;
  }
  #kast-afbeeldingen,
  #kt-sub .closet__config,
  #kt-sub .manual__closet__config {
    min-height: 250px;
  }
  #kast-indeling .kast__wrapper,
  #kt-sub .ToolBase .kast__wrapper {
    margin: 1px;
  }
  .w66-h2 {
    padding: 4px 0 0 0;
  }
  .w75-h2 {
    padding: 5px 0 0 0;
  }
  .w80-h2 {
    padding: 6px 0 0 0;
  }
  .w90-h1,
  .w90-h2,
  .w100-h1,
  .w100-h2,
  .w100-j2 {
    padding: 5px 0 0;
  }
  .w120-h2 {
    padding: 7px 0 0 0;
  }
  .w90-h1,
  .w100-h1,
  .hm90-h1,
  .hm100-h1,
  .a90-h1,
  .a100-h1,
  .b90-h1,
  .b100-h1,
  .c90-h1,
  .c100-h1,
  .d90-h1,
  .d100-h1,
  .el90-h1,
  .el100-h1,
  .er90-h1,
  .er100-h1,
  .f90-h1,
  .f100-h1,
  .fl90-h1,
  .fl100-h1,
  .fr90-h1,
  .fr100-h1,
  .g90-h1,
  .g100-h1,
  .h90-h1,
  .h100-h1,
  .hl90-h1,
  .hl100-h1,
  .hr90-h1,
  .hr100-h1,
  .i90-h1,
  .i100-h1,
  .j90-h1,
  .j100-h1,
  .s90-h1,
  .s100-h1 {
    background-size: 84px !important;
  }
  .w45-h1,
  .w50-h1,
  .hm45-h1,
  .hm50-h1,
  .a45-h1,
  .a50-h1,
  .b45-h1,
  .b50-h1,
  .c45-h1,
  .c50-h1,
  .d45-h1,
  .d50-h1,
  .g45-h1,
  .g50-h1,
  .h45-h1,
  .h50-h1,
  .i45-h1,
  .i50-h1,
  .j45-h1,
  .j50-h1,
  .s45-h1,
  .s50-h1,
  .x45-h1,
  .x50-h1 {
    background-size: 53px !important;
  }
  .w90-h2,
  .w100-h2,
  .hm90-h2,
  .hm100-h2,
  .a90-h2,
  .a100-h2,
  .b90-h2,
  .b100-h2,
  .c90-h2,
  .c100-h2,
  .d90-h2,
  .d100-h2,
  .dl90-h2,
  .dl100-h2,
  .dr90-h2,
  .dr100-h2,
  .el90-h2,
  .el100-h2,
  .er90-h2,
  .er100-h2,
  .fl90-h2,
  .fl100-h2,
  .fr90-h2,
  .fr100-h2,
  .f90-h2,
  .f100-h2,
  .g90-h2,
  .g100-h2,
  .gl90-h2,
  .gl100-h2,
  .gr90-h2,
  .gr100-h2,
  .h90-h2,
  .h100-h2,
  .hl90-h2,
  .hl200-h2,
  .hr90-h2,
  .hr100-h2,
  .i90-h2,
  .i100-h2,
  .j90-h2,
  .j100-h2,
  .jl90-h2,
  .jl100-h2,
  .jr90-h2,
  .jr100-h2,
  .kl90-h2,
  .kl100-h2,
  .kr90-h2,
  .kr100-h2,
  .n90-h2,
  .n100-h2,
  .s90-h2,
  .s100-h2,
  .x90-h2,
  .x100-h2,
  .xl90-h2,
  .xl100-h2,
  .xr90-h2,
  .xr100-h2 {
    background-size: 94px !important;
  }
  .w45-h2,
  .w50-h2,
  .hm45-h2,
  .hm50-h2,
  .a45-h2,
  .a50-h2,
  .b45-h2,
  .b50-h2,
  .c45-h2,
  .c50-h2,
  .d45-h2,
  .d50-h2,
  .f45-h2,
  .f50-h2,
  .g45-h2,
  .g50-h2,
  .h45-h2,
  .h50-h2,
  .i45-h2,
  .i50-h2,
  .j45-h2,
  .j50-h2,
  .s45-h2,
  .s50-h2,
  .x45-h2,
  .x50-h2 {
    background-size: 58px !important;
  }
  .w60-h2,
  .hm60-h2,
  .a60-h2,
  .b60-h2,
  .c60-h2,
  .d60-h2,
  .g60-h2,
  .h60-h2,
  .i60-h2,
  .j60-h2,
  .s60-h2,
  .x60-h2 {
    background-size: 67px !important;
  }
  .w66-h2,
  .hm66-h2,
  .a66-h2,
  .b66-h2,
  .c66-h2,
  .d66-h2,
  .dl66-h2,
  .dr66-h2,
  .el66-h2,
  .er66-h2,
  .fl66-h2,
  .fr66-h2,
  .s66-h2,
  .xl66-h2,
  .xr66-h2 {
    background-size: 74px !important;
  }
  .w75-h2,
  .hm75-h2,
  .a75-h2,
  .b75-h2,
  .c75-h2,
  .d75-h2,
  .el75-h2,
  .er75-h2,
  .fl75-h2,
  .fr75-h2,
  .g75-h2,
  .gl75-h2,
  .gr75-h2,
  .h75-h2,
  .hl75-h2,
  .hr75-h2,
  .i75-h2,
  .j75-h2,
  .jl75-h2,
  .jr75-h2,
  .s75-h2,
  .x75-h2,
  .xl75-h2,
  .xr75-h2 {
    background-size: 79px !important;
  }
  .w80-h2,
  .hm80-h2,
  .a80-h2,
  .b80-h2,
  .c80-h2,
  .el80-h2,
  .er80-h2,
  .d80-h2,
  .g80-h2,
  .h80-h2,
  .i80-h2,
  .j80-h2,
  .ll80-h2,
  .lr80-h2,
  .nl80-h2,
  .nr80-h2,
  .s80-h2,
  .tl80-h2,
  .tr80-h2,
  .x80-h2 {
    background-size: 79px !important;
  }
  .w50-j2,
  .hm50-j2,
  .j50-j2,
  .a50-j2,
  .g50-j2,
  .s50-j2,
  .h50-j2,
  .b50-j2,
  .p50-j2,
  .x50-j2,
  .d50-j2,
  .c50-j2,
  .m50-j2 {
    background-size: 58px !important;
  }
  .w100-j2,
  .hm100-j2,
  .d100-j2,
  .pl100-j2,
  .pr100-j2,
  .ml100-j2,
  .mr100-j2,
  .s100-j2,
  .b100-j2,
  .c100-j2,
  .a100-j2,
  .g100-j2,
  .j100-j2,
  .el100-j2,
  .er100-j2,
  .fr100-j2,
  .fl100-j2 {
    background-size: 94px !important;
  }
  .w120-h2,
  .hm120-h2,
  .a120-h2,
  .b120-h2,
  .c120-h2,
  .d120-h2,
  .el120-h2,
  .er120-h2,
  .g120-h2,
  .i120-h2,
  .j120-h2,
  .fr120-h2,
  .fl120-h2,
  .ll120-h2,
  .lr120-h2,
  .nl120-h2,
  .nr120-h2,
  .s120-h2,
  .tr120-h2,
  .tl120-h2,
  .x120-h2 {
    background-size: 108px !important;
  }
  #kast-afbeeldingen .w45-h1 > .chosen__closet__config,
  #kast-afbeeldingen .w50-h1 > .chosen__closet__config,
  #kast-afbeeldingen .w45-h1 > .choose__closet__config,
  #kast-afbeeldingen .w50-h1 > .choose__closet__config {
    width: 34px;
    height: 150px;
  }
  #kast-afbeeldingen .w45-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w50-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w50-j2 > .chosen__closet__config,
  #kast-afbeeldingen .w45-h2 > .choose__closet__config,
  #kast-afbeeldingen .w50-h2 > .choose__closet__config,
  #kast-afbeeldingen .w50-j2 > .choose__closet__config {
    width: 36px;
    height: 180px;
  }
  #kast-afbeeldingen .w60-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w60-h2 > .choose__closet__config {
    width: 65px;
    height: 180px;
  }
  #kast-afbeeldingen .w66-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w66-h2 > .choose__closet__config {
    width: 52px;
    height: 180px;
  }
  #kast-afbeeldingen .w75-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w75-h2 > .choose__closet__config {
    width: 57px;
    height: 190px;
  }
  #kast-afbeeldingen .w80-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w80-h2 > .choose__closet__config {
    width: 58px;
    height: 180px;
  }
  #kast-afbeeldingen .w90-h1 > .chosen__closet__config,
  #kast-afbeeldingen .w100-h1 > .chosen__closet__config,
  #kast-afbeeldingen .w90-h1 > .choose__closet__config,
  #kast-afbeeldingen .w100-h1 > .choose__closet__config {
    width: 65px;
    height: 165px;
  }
  #kast-afbeeldingen .w90-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w100-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w100-j2 > .chosen__closet__config,
  #kast-afbeeldingen .w90-h2 > .choose__closet__config,
  #kast-afbeeldingen .w100-h2 > .choose__closet__config,
  #kast-afbeeldingen .w100-j2 > .choose__closet__config {
    width: 72px;
    height: 190px;
  }
  #kast-afbeeldingen .w120-h2 > .chosen__closet__config,
  #kast-afbeeldingen .w120-h2 > .choose__closet__config {
    width: 87px;
    height: 174px;
  }
  .w45-h1 > .chosen__closet__config,
  .w50-h1 > .chosen__closet__config,
  .w45-h1 > .choose__closet__config,
  .w50-h1 > .choose__closet__config {
    width: 110px;
    height: 165px;
  }
  .w45-h2 > .chosen__closet__config,
  .w50-h2 > .chosen__closet__config,
  .w50-j2 > .chosen__closet__config {
    width: 110px;
    height: 220px;
  }
  .w45-h2 > .choose__closet__config,
  .w50-h2 > .choose__closet__config,
  .w50-j2 > .choose__closet__config {
    width: 76px;
    height: 195px;
  }
  .w90-h2 > .chosen__closet__config,
  .w100-h2 > .chosen__closet__config,
  .w100-j2 > .chosen__closet__config,
  .w90-h2 > .choose__closet__config,
  .w100-h2 > .choose__closet__config,
  .w100-j2 > .choose__closet__config {
    width: 138px;
    height: 230px;
  }
  .w90-h1 > .chosen__closet__config,
  .w100-h1 > .chosen__closet__config,
  .w90-h1 > .choose__closet__config,
  .w100-h1 > .choose__closet__config {
    width: 164px;
    height: 210px;
  }
  .w45-h1 label.chosen__closet__config,
  .w50-h1 label.chosen__closet__config {
    width: 105px;
    height: 210px;
  }
  .w45-h1 label.choose__closet__config,
  .w50-h1 label.choose__closet__config {
    width: 75px;
    height: 175px;
  }
  .w45-h2 label.chosen__closet__config,
  .w50-h2 label.chosen__closet__config,
  .w45-h2 label.choose__closet__config .w50-h2 label.choose__closet__config {
    width: 94px;
    height: 232px;
  }
  .w60-h2 label.chosen__closet__config,
  .w60-h2 label.choose__closet__config {
    width: 88px;
    height: 195px;
  }
  .w66-h2 label.chosen__closet__config,
  .w66-h2 label.choose__closet__config {
    width: 95px;
    height: 193px;
  }
  .w75-h2 label.chosen__closet__config,
  .w75-h2 label.choose__closet__config {
    width: 100px;
    height: 193px;
  }
  .w80-h2 label.chosen__closet__config,
  .w80-h2 label.choose__closet__config {
    width: 100px;
    height: 193px;
  }
  .w90-h2 label.choose__closet__config,
  .w100-h2 label.choose__closet__config {
    width: 135px;
    height: 280px;
  }
  .w90-h2 label.choose__closet__config,
  .w100-h2 label.choose__closet__config {
    width: 116px;
    height: 200px;
  }
  .w90-h1 label.chosen__closet__config,
  .w100-h1 label.chosen__closet__config {
    width: 135px;
    height: 210px;
  }
  .w90-h1 label.choose__closet__config,
  .w100-h1 label.choose__closet__config {
    width: 104px;
    height: 151px;
  }
  #kt-sub .a90-h1,
  #kt-sub .a100-h1,
  #kt-sub .b90-h1,
  #kt-sub .b100-h1,
  #kt-sub .c90-h1,
  #kt-sub .c100-h1,
  #kt-sub .d90-h1,
  #kt-sub .d100-h1,
  #kt-sub .el90-h1,
  #kt-sub .el100-h1,
  #kt-sub .er90-h1,
  #kt-sub .er100-h1,
  #kt-sub .fl90-h1,
  #kt-sub .fl100-h1,
  #kt-sub .fr90-h1,
  #kt-sub .fr100-h1,
  #kt-sub .g90-h1,
  #kt-sub .g100-h1,
  #kt-sub .h90-h1,
  #kt-sub .h100-h1,
  #kt-sub .hm90-h1,
  #kt-sub .hm100-h1,
  #kt-sub .i90-h1,
  #kt-sub .i100-h1,
  #kt-sub .j90-h1,
  #kt-sub .j100-h1,
  #kt-sub .s90-h1,
  #kt-sub .s100-h1 {
    background-size: 83px;
  }
  .wijzig {
    bottom: -30px;
    padding: 6px 0;
    width: 50px;
    text-align: left;
  }
}